<template>
  <svg viewBox="12 10  24 28" fill="none" version="1.1" id="icon" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px">
    <path
      d="M23.3137 32.4734C23.3137 32.8529 23.6213 33.1605 24.0008 33.1605C24.3802 33.1605 24.6878 32.8529 24.6878 32.4734V24.6874H32.4754C32.8548 24.6874 33.1624 24.3798 33.1624 24.0004C33.1624 23.6209 32.8548 23.3134 32.4754 23.3134H24.6878V15.5269C24.6878 15.1474 24.3802 14.8398 24.0008 14.8398C23.6213 14.8398 23.3137 15.1474 23.3137 15.5269V23.3134H15.5288C15.1494 23.3134 14.8418 23.6209 14.8418 24.0004C14.8418 24.3798 15.1494 24.6874 15.5288 24.6874H23.3137V32.4734Z"
      fill="black" />
  </svg>
</template>

<script>
  export default {
    name: "iconPlus"
  }
</script>

<style>

</style>