import {GROUP_TYPE} from "../../../nodes/group/index.js";

import copyNodesWithConnections from "../copyNodesWithConnections.js";
import dropExtraNodesPosition from "./dropExtraNodesPosition.js";

/**
 * Разгруппировывает узел-группу
 * @param {string} nodeId
 * @param {Object} nodes
 * @param {Object} connections
 */
export default (nodeId, nodes, connections, availableNodes) => {
    if(typeof nodeId !== "string" || typeof nodes !== "object" || !(nodeId in nodes)) {
        console.error("Cant ungroup node, wrong params");
        return nodes;
    }

    const node = nodes[nodeId];
    const {type, grouped} = nodes[nodeId];

    if(type !== GROUP_TYPE || typeof grouped !== "object") {
        console.error("Cant ungroup node, its not a group");
        return;
    }

    const reassignedGrouped = copyNodesWithConnections(grouped.nodes, grouped.connections, availableNodes);

    if(Object.values(reassignedGrouped.nodes).length > 0) {
        const groupPosition = node.position;
        const offsets = dropExtraNodesPosition(reassignedGrouped.nodes);

        Object.values(reassignedGrouped.nodes).forEach(node => {
            node.position[0] = groupPosition[0] + offsets[node.id]["position"][0];
            node.position[1] = groupPosition[1] + offsets[node.id]["position"][1];
        });
    }

    const updatedNodes = {
        ...reassignedGrouped.nodes,
        ...nodes,
    };
    delete updatedNodes[nodeId];

    const updatedConnections = {
        ...connections,
        ...reassignedGrouped.connections,
    };

    return {
        nodes: updatedNodes,
        connections: updatedConnections,
    };
}