<template>
  <svg viewBox="12 10  24 28" fill="none" version="1.1" id="icon" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px">
    <path
      d="M14.8398 23.9995C14.8398 23.6201 15.1474 23.3125 15.5269 23.3125H32.4734C32.8529 23.3125 33.1605 23.6201 33.1605 23.9995C33.1605 24.379 32.8529 24.6865 32.4734 24.6865H15.5269C15.1474 24.6865 14.8398 24.379 14.8398 23.9995Z"
      fill="black" />
  </svg>
</template>

<script>
  export default {
    name: "iconMinus"
  }
</script>

<style>

</style>