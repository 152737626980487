<template>
  <svg viewBox="10 8  29 29" fill="none" version="1.1" id="icon" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M32.9667 11.5979C32.9975 11.6065 33.0215 11.6305 33.0301 11.6613L33.5497 13.5126C33.5747 13.6017 33.7011 13.6017 33.7261 13.5126L34.2457 11.6613C34.2543 11.6305 34.2784 11.6065 34.3091 11.5979L36.16 11.0781C36.2491 11.0531 36.2491 10.9267 36.16 10.9017L34.3091 10.382C34.2784 10.3733 34.2543 10.3493 34.2457 10.3185L33.7261 8.46724C33.7011 8.37811 33.5747 8.37811 33.5497 8.46724L33.0301 10.3185C33.0215 10.3493 32.9975 10.3733 32.9667 10.382L31.1158 10.9017C31.0267 10.9267 31.0267 11.0531 31.1158 11.0781L32.9667 11.5979ZM33.2907 10.9899C33.4408 11.0651 33.5627 11.1869 33.6379 11.3369C33.7131 11.1869 33.835 11.0651 33.9851 10.9899C33.835 10.9147 33.7131 10.7929 33.6379 10.6429C33.5627 10.7929 33.4408 10.9147 33.2907 10.9899Z"
      fill="black" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M18.1307 28.2859H29.887C32.1818 26.5226 33.638 23.7609 33.638 20.6446C33.638 15.3177 29.3166 10.9899 23.986 10.9899C18.6553 10.9899 14.334 15.3177 14.334 20.6446C14.334 23.7609 15.8359 26.5226 18.1307 28.2859ZM18.6173 26.9119H29.3999C31.1632 25.3972 32.2639 23.1589 32.2639 20.6446C32.2639 16.0757 28.5569 12.364 23.986 12.364C19.415 12.364 15.708 16.0757 15.708 20.6446C15.708 23.1466 16.8397 25.3893 18.6173 26.9119Z"
      fill="black" />
    <path d="M29.7039 29.4755H18.2679V30.8482H29.7039V29.4755Z" fill="black" />
    <path d="M19.4115 32.0379H28.5603V33.4106H19.4115V32.0379Z" fill="black" />
    <path d="M27.4167 34.6003H20.5551V35.973H27.4167V34.6003Z" fill="black" />
  </svg>
</template>

<script>
  export default {
    name: "iconInfo"
  }
</script>

<style>

</style>