import ungroupNode from "../helpers/workflow/nodes/ungroupNode.js";
import deleteNodeConnections from "../helpers/workflow/connections/deleteNodeConnections.js";
import copyNodesWithConnections from "../helpers/workflow/copyNodesWithConnections.js";
import dropExtraNodesPosition from "../helpers/workflow/nodes/dropExtraNodesPosition.js";
import sortedNodesByPosition from "../helpers/workflow/nodes/sortedNodesByPosition.js";
import shiftNodesPosition from "../helpers/workflow/nodes/shiftNodesPosition.js";
import createNode from "../helpers/workflow/nodes/createNode.js";
import getNodeType from "../helpers/workflow/nodes/getNodeType.js";

import {GROUP_TYPE} from "../nodes/group/index.js";
import {GROUP_INPUT_MAIN_OUTPUT, GROUP_INPUT_TYPE} from "../nodes/group-input/index.js";
import {GROUP_OUTPUT_TYPE} from "../nodes/group-output/index.js";

import deepCopy from "../helpers/deepCopy.js";
import deleteNode from "../helpers/workflow/nodes/deleteNode.js";

export default {
    props: {
        isSubgroup: {
            type: Boolean,
            required: false
        },
    },
    data() {
        return {
            subgroupContexts: [],
            subgroupIsReady: true,
        };
    },
    methods: {
        addGroupContext(context) {
            if(!context) {
                console.error("Cant add group context");
                return;
            }

            if(this.isSubgroup) {
                this.$emit("subgroupSetContext", context);
            } else {
                this.subgroupContexts.push(context);
                this.$emit("changeSubgroupContexts", this.subgroupContexts);
            }

            this.fullReset();
        },
        closeLastContext() {
            this.subgroupContexts = this.subgroupContexts.slice(0, this.subgroupContexts.length-1);

            if(this.subgroupContexts.length === 0) {
                this.setInFocus(true);
            }

            this.$emit("changeSubgroupContexts", this.subgroupContexts);
        },
        closeAllContexts() {
            this.subgroupContexts = [];
            this.setInFocus(true);

            this.$emit("changeSubgroupContexts", this.subgroupContexts);
        },
        changeContextTo(index) {
            if(typeof index === "undefined") {
                console.error("Cant set context, wrong params");
                return;
            }

            if(index === this.subgroupContexts.length - 1) {
                return;
            }

            this.subgroupContexts = this.subgroupContexts.slice(0, index+1);

            this.$emit("changeSubgroupContexts", this.subgroupContexts);
        },
        deleteOldGroupNodes() {
            let updatedNodes = deepCopy(this.nodes);
            let updatedConnections = deepCopy(this.connections);

            const dontTouchNodes = [GROUP_INPUT_TYPE, GROUP_OUTPUT_TYPE];

            Object.values(updatedNodes).forEach(node => {
                if(!node.selected || dontTouchNodes.includes(node.type)) return;

                updatedConnections = deleteNodeConnections(node.id, updatedConnections);
                deleteNode(node.id, updatedNodes);
            });

            this.$emit("update:connections", updatedConnections);
            this.$nextTick(() => {
                this.$emit("update:nodes", updatedNodes);
            });
        },
        groupSelectedNodes() {
            this.takeSnapshot();

            const {nodes, connections} = copyNodesWithConnections(this.selectedNodes, this.connections, this.fullAvailableNodes);
           
            // don't create group if selected one node
            if(Object.keys(nodes).length === 1) return;
            
            Object.values(nodes).forEach(node => node.selected = false);

            dropExtraNodesPosition(nodes);
            shiftNodesPosition([this.rightOptions.cellSize * 5, 0], nodes);

            const sortedPositions = sortedNodesByPosition(nodes);

            const selectedNodes = Object.keys(this.selectedNodes);
            const filteredNodes = Object.entries(this.connections).filter(node => !selectedNodes.includes(node[0]));


        // first node
            const filteredNodesConnects = filteredNodes.map(item => item[1])
            const firstNodeInGroupIfInFlow = selectedNodes.find(item => JSON.stringify(filteredNodesConnects).includes(item))
            const firstNodeInGroupIfFirstInFlow = selectedNodes.find(item => !JSON.stringify(Object.values(this.connections)).includes(item))

            const idfirstNodeOfSelected = firstNodeInGroupIfInFlow ?? firstNodeInGroupIfFirstInFlow
            const firstNodeOfSelected = this.selectedNodes[idfirstNodeOfSelected]

            const parents = filteredNodes.reduce((arr, [key, value]) => {
                if(JSON.stringify(value).includes(firstNodeOfSelected.id)) {
                    let name = Object.entries(value).filter(item => item[1][0].endNodeId === firstNodeOfSelected.id)[0][0]
                    arr.push({name, id: key})
                }
                return arr
            }, [])
       
            const firstNodeInGroup = sortedPositions.find(node => node.db_id === firstNodeOfSelected.db_id)

            const positionInputNode = [firstNodeInGroup.position[0] - this.rightOptions.cellSize * 5, firstNodeInGroup.position[1]]
            const groupInput = createNode({type: GROUP_INPUT_TYPE, position: positionInputNode}, this.fullAvailableNodes);

            nodes[groupInput.id] = groupInput;
                        
            if(getNodeType(firstNodeInGroup, this.fullAvailableNodes).input) {
                connections[groupInput.id] = {};
                connections[groupInput.id][GROUP_INPUT_MAIN_OUTPUT] = [{endNodeId: firstNodeInGroup.id}];
            }

        // last node
            const idLastNodeOfSelected = Object.entries(this.selectedNodes).map(item => {
                if(item[1]?.overwrittenNodeType?.outputs?.length > 1 && item[1].db_id !== firstNodeOfSelected.db_id){
                    if(Object.entries(this.connections[item[0]]).find(node => !selectedNodes.includes(node[1][0].endNodeId))) return item[0]
                }else {
                    let objConnections = Object.keys(this.connections)
                    let objConnectionsJSON = JSON.stringify(Object.values(this.connections))
        
                    return selectedNodes.find(selectNode => (!objConnections.includes(selectNode) || Object.keys(this.connections[selectNode]).length === 0) && objConnectionsJSON.includes(selectNode) )
                }
            }).filter(item => item)[0]

            const lastNodeInGroup = sortedPositions
                .find(node => node.db_id === this.selectedNodes[idLastNodeOfSelected].db_id)

            const groupOutput = createNode({type: GROUP_OUTPUT_TYPE, position: [
                    lastNodeInGroup.position[0] + this.rightOptions.cellSize * 8,
                    lastNodeInGroup.position[1],
            ]}, this.fullAvailableNodes);

            connections[lastNodeInGroup.id] = {['main']: [{endNodeId: groupOutput.id}]}
            nodes[groupOutput.id] = groupOutput;

            //set start out name for last node in group
            nodes[lastNodeInGroup.id].overwrittenNodeType = {"outputNames":["",""],"outputs":["main","main1"]}


            // endNode for group
            const filteredSelectedNodes = Object.entries(this.connections).filter(node => selectedNodes.includes(node[0]))
            const endNode = filteredSelectedNodes.map(nodes => {
                const node = Object.entries(nodes[1])
                    .filter(item => !selectedNodes.includes(item[1][0].endNodeId))
                    
                if(node.length > 0) return node.filter(arr => arr.length > 0)
            }).filter(item => item)
            .filter(item => item.length > 0)[0]

            const endNodesId = endNode
                ? endNode.reduce((arr, item) => {
                        arr.push(item[1][0].endNodeId)
                        return arr
                    }, [])
                : null

            const firstNodePosition = firstNodeOfSelected.position;
            const position = [firstNodePosition[0], firstNodePosition[1]];
           
            const node = this.addNode({
                position,
                type: GROUP_TYPE,
                grouped: {
                    nodes,
                    connections
                },
                parents,
                endNodeId: endNodesId,
                groupOutput: groupOutput.id,
            });

            this.$emit("group", node);
            this.$nextTick(() => {
                this.deleteOldGroupNodes();
            });
        },

        ungroupNode(node) {
            if(typeof node !== "object" && node.type !== GROUP_TYPE) {
                console.error("Cant ungroup group, wrong params");
                return;
            }

            const valuesNodesOfGroup = Object.values(node.grouped.nodes)

            const groupInput = valuesNodesOfGroup.find(node => node.type === 'groupInput');
            const firstNodeInGroupId = node.grouped.connections[groupInput.id]['main'][0].endNodeId;
            const firstNodeInGroup = node.grouped.nodes[firstNodeInGroupId];
            const groupOutput = valuesNodesOfGroup.find(node => node.type === 'groupOutput');

            // set correct order (first -> groupInput node, second -> firstNodeInGroup, last -> groupOutput)
            let newStructureOfGroup = valuesNodesOfGroup
                .filter(node => node.type !== 'groupInput')
                .filter(node => node.id !== firstNodeInGroupId)
                .filter(node => node.type !== 'groupOutput')

            newStructureOfGroup.unshift(firstNodeInGroup)
            newStructureOfGroup.unshift(groupInput)
            newStructureOfGroup.push(groupOutput)

            // change structure for array
            this.nodes[node.id].grouped.nodes = newStructureOfGroup
            this.takeSnapshot();

            const updatedConnections = deleteNodeConnections(node.id, this.connections);
            const {nodes, connections} = ungroupNode(node.id, this.nodes, updatedConnections, this.fullAvailableNodes);

            const newConnect = Object.entries(connections).filter(node => !Object.keys(updatedConnections).includes(node[0]))
            const connectKeys = newConnect.map(item => item[0])
            const groupId = [node.id]
          
            const nodeBeforeGroup = Object.entries(this.connections).map(node => {
                let id = Object.entries(node[1])
                    .filter(item => groupId.includes(item[1][0].endNodeId))

                if(id.length > 0) return {name: id[0][0], id: node[0]}
            }).filter(item => item);

            let startGroupId = null
            if(connectKeys.length === 1) startGroupId = connectKeys[0]
            else startGroupId = newConnect.map(node => {
                const id = Object.entries(node[1])
                    .filter(item => connectKeys.includes(item[1][0].endNodeId))

                if(id.length > 0) return node[0]
            }).filter(item => item)[0];

            const nodeAfterGroupId = this.connections[node.id]
                ? Object.values(this.connections[node.id]).map(item => item[0].endNodeId)
                : null

            const endGroupId = newConnect.map(node => {
                const id = Object.entries(node[1])
                    .filter(item => !connectKeys.includes(item[1][0].endNodeId))
                
                if(id.length > 0) return id[0][1][0].endNodeId
            }).filter(item => item)[0];

            this.$emit("ungroup", { parents: nodeBeforeGroup, 
                                    startGroupId, 
                                    endNodeId: nodeAfterGroupId,  
                                    endGroupId });
            this.$emit("update:nodes", nodes);

            this.$nextTick(() => {
                this.$emit("update:connections", {
                    ...updatedConnections,
                    ...connections
                });
            });
        },
        passEventFromSubgroup(event) {
            if(typeof event !== "object" && typeof event.passedEventName !== "string" && !Array.isArray(event.subgroupContextPath)) {
                console.error("Cant pass event from subgroup, wrong params");
                return;
            }

            this.$emit(event.passedEventName, event);
        }
    },
    beforeDestroy() {
        if(this.isSubgroup) {
            this.deselectAll();
        }
    }
};