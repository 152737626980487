<template>
  <svg id="icon" viewBox="0 0 64 64" data-name="Material Expand" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M60,18H44V4a2,2,0,0,0-2-2H8A2,2,0,0,0,6,4V34H4a2,2,0,0,0-2,2v4a6.006,6.006,0,0,0,6,6H24v4H22a2,2,0,0,0-2,2v4a6.006,6.006,0,0,0,6,6H56a6.006,6.006,0,0,0,6-6V20A2,2,0,0,0,60,18ZM10,6H40V40a2,2,0,0,1-4,0V36a2,2,0,0,0-2-2H10ZM6,40V38H32v2a5.972,5.972,0,0,0,.343,2H8A2,2,0,0,1,6,40ZM26,58a2,2,0,0,1-2-2V54H50v2a5.972,5.972,0,0,0,.343,2Zm32-2a2,2,0,0,1-4,0V52a2,2,0,0,0-2-2H28V46H38a6.006,6.006,0,0,0,6-6V22H58Z" />
    <rect height="4" width="4" x="14" y="10" />
    <rect height="4" width="14" x="22" y="10" />
    <rect height="4" width="22" x="14" y="18" />
    <rect height="4" width="22" x="14" y="26" />
    <rect height="4" width="6" x="48" y="26" />
    <rect height="4" width="6" x="48" y="34" />
    <rect height="4" width="6" x="48" y="42" />
  </svg>

</template>

<script>
  export default {
    name: "iconCopy"
  }
</script>

<style>

</style>