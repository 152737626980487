import generateNodeId from "./nodes/generateNodeId.js";
import optimizeEmptyConnectionsStruct from "./optimizeEmptyConnectionsStruct.js";
import deepCopy from "../deepCopy.js";
import nodeCanBeCopied from "./nodes/nodeCanBeCopied.js";

/**
 * Pure
 * Copy nodes with connections and give them new ids
 * @param {Object} nodeList
 * @param {object} connections
 * @param {object} availableNodes
 */
export default (nodeList, connections, availableNodes) => {
    if(typeof nodeList !== 'object' || typeof connections !== 'object' || typeof availableNodes !== 'object') {
        console.error('Cant copy nodes');
        return null;
    }

    const copyNodeList = Array.isArray(nodeList)
        ? deepCopy(nodeList)
        : Object.entries(deepCopy(nodeList))

    const newNodeList = copyNodeList.reduce((acc, node) => {
        const nodeObj = node[1] ?? node

        if(nodeCanBeCopied(nodeObj, availableNodes)) {
            acc[nodeObj.id] = nodeObj;
        }

        return acc;
    }, {});
    let newConnections = deepCopy(connections);

    const newIdsAssociations = {};

    /**
     * Re-assign ids
     */
    Object.entries(newNodeList).forEach(nodePair => {
        const newId = generateNodeId();

        newIdsAssociations[nodePair[0]] = newId;

        newNodeList[newId] = nodePair[1];
        newNodeList[newId].id = newId;

        delete newNodeList[nodePair[0]];
    });

    const oldIds = Object.keys(newIdsAssociations);

    /**
     * Delete non-used connections
     */
    for(const startNodeId in newConnections) {
        const connectionsInfo = newConnections[startNodeId];

        if(!oldIds.includes(startNodeId)) {
            delete newConnections[startNodeId];
        } else {
            for(const outputName in connectionsInfo) {
                let outputInfo = connectionsInfo[outputName];

                newConnections[startNodeId][outputName] = outputInfo.filter(endNodeObj => {
                    return oldIds.includes(endNodeObj['endNodeId']);
                });
            }
        }
    }

    newConnections = optimizeEmptyConnectionsStruct(newConnections);

    /**
     * Replace old ids by new ids
     */
    for(const startNodeId in newConnections) {
        const connectionsInfo = newConnections[startNodeId];

        newConnections[newIdsAssociations[startNodeId]] = connectionsInfo;
        delete newConnections[startNodeId];

        for (const outputName in connectionsInfo) {
            let outputInfo = connectionsInfo[outputName];

            outputInfo = outputInfo.map(endNodeObj => {
                endNodeObj['endNodeId'] = newIdsAssociations[endNodeObj['endNodeId']];
                return endNodeObj;
            });
        }
    }

    return {
        nodes: newNodeList,
        connections: newConnections
    };
}