/**
 * Создаёт геометрию плоскости в нулевых координатах.
 * @return {BufferDataObject}
 */
export default () => {
    return {
        position: [
            -1, -1, 0,
            -1, 1, 0,
            1, 1, 0,
            1, -1, 0
        ],
        uv: [
            0, 0,
            0, 1,
            1, 1,
            1, 0
        ],
        indices: [
            0, 1, 2, 2, 3, 0
        ]
    }
};