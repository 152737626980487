<template>
  <svg version="1.1" id="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" viewBox="0 0 377.343 377.343" style="enable-background:new 0 0 377.343 377.343;" xml:space="preserve">
    <g>
      <path d="M120.641,111.544h35.143V212.04h-35.143V111.544z M321.407,73.223v230.911c0,10.866-8.843,19.71-19.71,19.71H75.651
		c-10.875,0-19.718-8.844-19.718-19.71V73.223c0-10.87,8.843-19.713,19.718-19.713h226.046
		C312.564,53.505,321.407,62.353,321.407,73.223z M188.67,111.544c0-7.91-3.329-14.786-9.978-20.638
		c-6.651-5.85-14.364-8.769-23.133-8.769h-34.694c-8.843,0-16.576,2.936-23.185,8.821c-6.619,5.883-9.924,12.742-9.924,20.586
		v100.698c0,7.913,3.267,14.753,9.806,20.535c6.539,5.779,14.231,8.669,23.072,8.669h34.921c8.768,0,16.479-2.922,23.13-8.767
		c6.657-5.855,9.985-12.733,9.985-20.646V111.544z M374.121,35.439v306.464c0,19.573-15.875,35.439-35.444,35.439H38.665
		c-19.569,0-35.443-15.866-35.443-35.439V35.439C3.221,15.869,19.096,0,38.665,0h300.012
		C358.246,0.005,374.121,15.874,374.121,35.439z M335.382,73.223c0-18.569-15.113-33.688-33.685-33.688H75.651
		c-18.574,0-33.693,15.114-33.693,33.688v230.911c0,18.565,15.114,33.685,33.693,33.685h226.046
		c18.566,0,33.685-15.113,33.685-33.685V73.223z" /></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>

</template>

<script>
  export default {
    name: "iconHotkey"
  }
</script>

<style>

</style>