<template>
  <graph-editor-ui-guide-col-wrapper>
    <graph-editor-ui-guide-col
        v-for="(col, index) in cols"
        :key="index"
        v-bind="col"
    />
  </graph-editor-ui-guide-col-wrapper>
</template>

<script>
import GraphEditorUiGuideColWrapper from "../GraphEditorUiGuideColWrapper.vue";
import GraphEditorUiGuideCol from "../GraphEditorUiGuideCol.vue";

export default {
  components: {
    GraphEditorUiGuideCol,
    GraphEditorUiGuideColWrapper
  },
  data() {
    return {
      cols: [
        {
          icon: 'iconNode',
          title: 'Using node actions',
          description: 'To delete a node using node actions, the node must be selected. Then you need to click on the "trash can" icon located above the node, after which the node will be deleted'
        },
        {
          icon: 'iconHotkey',
          title: 'Using a hotkey',
          description: 'In order to delete nodes using a hotkey, you need to select them and then press the Delete key'
        },
      ],
    };
  },
}
</script>