/**
 * Возвращает ссылки на выделенные ноды
 * @param {Object} nodeList
 */
export default (nodeList) => {
    if(typeof nodeList !== "object") {
        console.error('Cant get selected nodes wrong params');
        return;
    }

    return Object.entries(nodeList).reduce((acc, pair) => {
        if(pair[1].selected) {
            acc[pair[0]] = pair[1];
        }

        return acc;
    }, {});
}