<template>

  <div
      class="ui-guide"
      :class="{visible}"
      @click="close"
      @mousedown.stop=""
  >

    <div
        v-if="isMounted"
        class="ui-guide__body"
        :style="{
          height: `95%`,
        }"
        @click.stop
    >
      <GraphEditorUiGuidePage
          :title="currentPageData.title"
          :component="currentPageData.component"
      />
      <GraphEditorUiGuideControls
          :pagesCount="pages.length"
          :currentPage="currentPage"
          @close="close"
          @back="back"
          @next="next"
      />
    </div>

  </div>

</template>

<script>
import GraphEditorUiGuideControls from "./GraphEditorUiGuideControls.vue";
import GraphEditorUiGuidePage from "./GraphEditorUiGuidePage.vue";

import GraphEditorUiGuideMovePage from "./pages/GraphEditorUiGuideMovePage.vue";
import GraphEditorUiGuideAddNodesPage from "./pages/GraphEditorUiGuideAddNodesPage.vue";
import GraphEditorUiGuideRemoveNodesPage from "./pages/GraphEditorUiGuideRemoveNodesPage.vue";
import GraphEditorUiGuideAddConnectionsPage from "./pages/GraphEditorUiGuideAddConnectionsPage.vue";
import GraphEditorUiGuideRemoveConnectionsPage from "./pages/GraphEditorUiGuideRemoveConnectionsPage.vue";
import GraphEditorUiGuideHotkeysPage from "./pages/GraphEditorUiGuideHotkeysPage.vue";

export default {
  components: {
    GraphEditorUiGuideControls,
    GraphEditorUiGuidePage,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    editorInFocus: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      isMounted: false,
      currentPage: 0,
      pages: [
        {
          title: 'Move',
          component: GraphEditorUiGuideMovePage
        },
        {
          title: 'Adding nodes',
          component: GraphEditorUiGuideAddNodesPage
        },
        {
          title: 'Removing nodes',
          component: GraphEditorUiGuideRemoveNodesPage
        },
        {
          title: 'Adding connections',
          component: GraphEditorUiGuideAddConnectionsPage
        },
        {
          title: 'Deleting links',
          component: GraphEditorUiGuideRemoveConnectionsPage
        },
        {
          title: 'Hotkeys',
          component: GraphEditorUiGuideHotkeysPage
        }
      ],
    }
  },
  computed: {
    root() {
      return this.$parent.$parent.$el;
    },
    currentPageData() {
      return this.pages[this.currentPage];
    }
  },
  methods: {
    close() {
      this.$emit('update:visible', false);
    },
    next() {
      this.currentPage = Math.min(this.pages.length-1, this.currentPage+1);
    },
    back() {
      this.currentPage = Math.max(0, this.currentPage-1);
    },
    keyboardTryToChangePage(event) {
      if(!this.isMounted || !this.visible || !this.editorInFocus) {
        return;
      }

      switch (event.keyCode) {
        case 37:
          event.preventDefault();
          this.back();
          break;
        case 39:
          event.preventDefault();
          this.next();
          break;
        case 27:
          event.preventDefault();
          this.close()
          break;
      }

    }
  },
  mounted() {
    this.isMounted = true;

    window.addEventListener('keydown', this.keyboardTryToChangePage.bind(this));
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keyboardTryToChangePage.bind(this));
  }
}
</script>

<style scoped lang="scss">

.ui-guide {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 20;
  background-color: rgba(255,255,255,.8);
  visibility: hidden;
  opacity: 0;
  will-change: visibility, opacity;
  transition: opacity .3s, visibility .2s;
  cursor: pointer;

  &.visible {
    opacity: 1;
    visibility: visible;
    transition: opacity .3s;
  }
}

.ui-guide__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  max-width: 1000px;
  width: 100%;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.3);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 1000px) {
    max-width: none;
  }
}
</style>