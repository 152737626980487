<template>
  <graph-editor-ui-guide-col-wrapper>
    <graph-editor-ui-guide-col
        v-for="(col, index) in cols"
        :key="index"
        v-bind="col"
    />
  </graph-editor-ui-guide-col-wrapper>
</template>

<script>
import GraphEditorUiGuideColWrapper from "../GraphEditorUiGuideColWrapper.vue";
import GraphEditorUiGuideCol from "../GraphEditorUiGuideCol.vue";

export default {
  components: {
    GraphEditorUiGuideCol,
    GraphEditorUiGuideColWrapper
  },
  data() {
    return {
      cols: [
        {
          icon: 'iconDeleteConnection',
          title: 'On hover',
          description: 'To delete a connection, you need to hover the cursor over the connection that you want to delete, after which actions will appear, among which there is an action with which you can delete the connection'
        },
      ],
    };
  },
}
</script>

<styles></styles>