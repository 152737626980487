export const vertexShader = `
    precision lowp float;
    
    attribute vec3 position;
    attribute vec2 uv;
    
    varying vec3 v_position;
    varying vec2 v_uv;
    
    uniform float u_scale;
    uniform float u_cell;
    uniform float u_size;
    uniform vec2 u_mouse;
    uniform vec2 u_resolution;
    uniform vec3 u_color;
    uniform vec2 u_offset;
   
    void main() {
        v_position = position;
        v_uv = uv;
        
        gl_Position = vec4(position, 1.);
    }
`;

export const fragmentShader = `
    precision lowp float;
    
    varying vec3 v_position;
    varying vec2 v_uv;
    
    uniform float u_scale;
    uniform float u_cell;
    uniform float u_size;
    uniform vec2 u_mouse;
    uniform vec2 u_resolution;
    uniform vec3 u_color;
    uniform vec2 u_offset;
    
    void main() {
        float cellCount = u_resolution.y / u_cell; 
        float cellSize = u_resolution.x / cellCount;
        float realCellSize = cellSize / u_resolution.x;
        vec2 t_offset = u_offset / u_resolution / u_scale;
        float fov = u_resolution.x / u_resolution.y;
        
        vec2 t_uv = v_uv;
        t_uv += t_offset * u_scale;
        
        t_uv.x *= fov;
        t_uv.y = 1. - t_uv.y;

        t_uv /= u_scale;    

        t_uv += realCellSize*.5;

        vec2 t_mouse = u_mouse;
        t_mouse += t_offset * u_scale;
        
        t_mouse.x *= fov;
        t_mouse.y = 1. - t_mouse.y;
        
        t_mouse += realCellSize*.5;
        t_mouse /=  u_scale;
        
        vec2 cell = fract(t_uv * cellCount);
        vec2 cellIndex = t_uv * cellCount - cell;
        
        float pointCellPart = u_size / cellSize;
        
        float mouseUvDist = distance(t_uv, t_mouse);
        float mouseUvDistClipped = pointCellPart * smoothstep(0.2, 0.01, mouseUvDist);
        
        float dist = 1. - distance(vec2(.5), cell);
        float clippedDist = step(1. - pointCellPart - mouseUvDistClipped, dist);
        
        gl_FragColor = vec4(u_color, clippedDist);
    }
`;