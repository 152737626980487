<template>
  <svg viewBox="10 10  29 27" fill="none"  version="1.1" id="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px">
    <path d="M22.1727 11.542C22.1727 10.7832 22.7878 10.168 23.5467 10.168H24.453C25.2118 10.168 25.827 10.7832 25.827 11.542H29.4777C30.2365 11.542 30.8517 12.1572 30.8517 12.9161H17.148C17.148 12.1572 17.7631 11.542 18.522 11.542H22.1727Z" fill="black"/>
    <path d="M21.3961 31.5133C21.3961 31.8917 21.7029 32.1985 22.0813 32.1985C22.4597 32.1985 22.7665 31.8917 22.7665 31.5133V19.1432C22.7665 18.7648 22.4597 18.458 22.0813 18.458C21.7029 18.458 21.3961 18.7648 21.3961 19.1432V31.5133Z" fill="black"/>
    <path d="M25.8727 32.1985C25.4943 32.1985 25.1875 31.8917 25.1875 31.5133V19.1432C25.1875 18.7648 25.4943 18.458 25.8727 18.458C26.2511 18.458 26.5579 18.7648 26.5579 19.1432V31.5133C26.5579 31.8917 26.2511 32.1985 25.8727 32.1985Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0779 15.3204C16.0388 14.5356 16.6645 13.8779 17.4503 13.8779H30.5494C31.3352 13.8779 31.9609 14.5356 31.9218 15.3204L30.9168 35.473C30.8804 36.2044 30.2767 36.7787 29.5445 36.7787H18.4552C17.7229 36.7787 17.1193 36.2044 17.0829 35.473L16.0779 15.3204ZM17.4503 15.2519H30.5494L29.5445 35.4046H18.4552L17.4503 15.2519Z" fill="black"/>
  </svg>
</template>

<script>
  export default {
    name: "iconDelete"
  }
</script>

<style>

</style>