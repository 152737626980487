import ic from "./icon.svg";

import {SYSTEM_CATEGORY} from "../categories.js";
export const GROUP_INPUT_TYPE = "groupInput";
export const GROUP_INPUT_MAIN_OUTPUT = "main";

export default {
    name: 'Inpit',
    description: 'Group node',
    icon: ic + "#icon",
    input: true,
    outputs: [GROUP_INPUT_MAIN_OUTPUT],
    category: SYSTEM_CATEGORY,
    canBeDeleted: false,
    canBeCopied: false,
}