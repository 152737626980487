import calculateDistance from "./math/calculateDistance.js";

/**
 * Возвращает центр масс позиций для списка узлов
 * Полезно для более првильного высчитывания фокуса
 * @param nodeList
 * @returns {*[]|number[]}
 */
export default (nodeList) => {
    if(typeof nodeList !== "object") {
        console.error('Cant get average node position, wrong params')
        return;
    }

    const nodesCount = Object.keys(nodeList).length;

    if(nodesCount === 0) return;
    if(nodesCount === 1) {
        const node = Object.values(nodeList)[0];
        return [...node.position];
    }

    const nodesArray = Object.values(nodeList);

    const masses = nodesArray.map(node => nodesArray.reduce((acc, innerNode) => {
        if(node.id === innerNode.id) return acc;

        const distance = 1/Math.pow(calculateDistance(node.position, innerNode.position), 10);

        if(Number.isNaN(acc)) return distance;

        return acc < distance ? acc : distance;
    }, Number.NaN));

    const massesCoordsXProduction = nodesArray.reduce((acc, node, index) => {
        return acc + node.position[0] * masses[index];
    }, 0);
    const massesCoordsYProduction = nodesArray.reduce((acc, node, index) => {
        return acc + node.position[1] * masses[index];
    }, 0);
    const massesSum = masses.reduce((acc, mass) => acc + mass, 0);

    return [
        massesCoordsXProduction/massesSum,
        massesCoordsYProduction/massesSum
    ];
}