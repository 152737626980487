/**
 * Возвращает входящие соединения для ноды
 * @param {string} nodeId
 * @param {Object} connections
 */
export default (nodeId, connections) => {
    if(typeof nodeId !== "string" || typeof  connections !== 'object') {
        console.error('Cant get ingoing connections, wrong params');
        return;
    }

    let result = {};

    for(const nodeStartId in connections) {
        const nodeStartInfo = connections[nodeStartId];

        if(typeof nodeStartInfo !== "object") {
            console.error("Start node data isnt object");
            continue;
        }

        if(nodeStartId === nodeId) continue;

        for(const outputName in nodeStartInfo) {
            const outputInfo = nodeStartInfo[outputName];

            if(!Array.isArray(outputInfo)) {
                console.error("Output info isnt array");
                continue;
            }

            const neededNodeIndex = outputInfo.findIndex((endNodeObject) => endNodeObject['endNodeId'] === nodeId);

            if(neededNodeIndex === -1) continue;

            if(!result[nodeStartId]) {
                result[nodeStartId] = [];
            }

            result[nodeStartId].push(outputName);
        }
    }

    return result;
}