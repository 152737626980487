/**
 * Возвращает объект отфильтрованных узлов.
 * @param {string} searchName
 * @param {Object} nodesOnCanvas
 * @param {Boolean} isConnections
 * @return {Object}
 */
export default (searchName, nodesOnCanvas, isConnections = false) => {
    if(!searchName || typeof searchName !== 'string' || !nodesOnCanvas || typeof nodesOnCanvas !== 'object') {
        console.error('Cant filter nodes, wrong params');
        return;
    }
    const transformedSearchName = searchName.toLowerCase().replaceAll(' ', '');

    const searchByDbId = Object.entries(nodesOnCanvas)
        .filter(node =>
            node[1]['db_id']
                .toString()
                .includes(transformedSearchName)
        )

    const searchByDisplayName = Object.entries(nodesOnCanvas)
        .filter(node =>
            node[1]['displayName']
                .toLowerCase()
                .replaceAll(' ', '')
                .includes(transformedSearchName)
        )

    const searchByOutputNames = Object.entries(nodesOnCanvas)
        .filter(node => {
            if(node[1]?.overwrittenNodeType?.outputNames){
                return node[1].overwrittenNodeType.outputNames?.some(item =>
                    item.toLowerCase()
                        .replaceAll(' ', '')
                        .includes(transformedSearchName)
                )
            }
        })

    const searchedNodes = searchByDbId.length
        ? searchByDbId
        : searchByDisplayName

    return isConnections
        ? searchByOutputNames?.map(node => node[1])
        : searchedNodes?.map(node => node[1])
}