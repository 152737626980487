<template>
  <graph-editor-ui-guide-col-wrapper>
    <graph-editor-ui-guide-col
        v-for="(col, index) in cols"
        :key="index"
        v-bind="col"
    />
  </graph-editor-ui-guide-col-wrapper>
</template>

<script>
import GraphEditorUiGuideColWrapper from "../GraphEditorUiGuideColWrapper.vue";
import GraphEditorUiGuideCol from "../GraphEditorUiGuideCol.vue";

export default {
  components: {
    GraphEditorUiGuideCol,
    GraphEditorUiGuideColWrapper
  },
  data() {
    return {
      cols: [
        {
          icon: 'iconMenu',
          title: 'Using the menu',
          description: 'To add a node using the menu, you need to click the "Add node" button at the top - a menu will open in which you can select a node, which will then be displayed on the screen'
        },
        {
          icon: 'iconOutput',
          title: 'Using a pre-created node',
          description: 'You can left-click on the nodes output and release, after which a menu will appear in which you can select a node to create, which will be immediately connected to the nodes output'
        },
      ],
    };
  },
}
</script>

<styles></styles>