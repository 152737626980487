import ic from "./icon.svg";

import {SYSTEM_CATEGORY} from "../categories.js";

export const GROUP_TYPE = "group";

export default {
    name: 'Group',
    description: 'Group node',
    icon: ic + "#icon",
    input: true,
    outputs: [],
    category: SYSTEM_CATEGORY
};