<template>
  <div class="ui-guide-controls">
    <div class="ui-guide-controls__top">{{currentPage+1}}/{{pagesCount}}</div>
    <div class="ui-guide-controls__bottom">
      <div class="ui-guide-controls__navigation">
        <graph-editor-button
            class="ui-guide-controls__navigation-btn"
            @click="$emit('back')"
        >
          Back
        </graph-editor-button>
        <graph-editor-button
            class="ui-guide-controls__navigation-btn"
            @click="$emit('next')"
        >
          Next
        </graph-editor-button>
      </div>
      <div class="ui-guide-controls__aside">
        <graph-editor-button
          @click.native="$emit('close')"
        >
          Close
        </graph-editor-button>
      </div>
    </div>
  </div>
</template>

<script>
import GraphEditorButton from "../utils/GraphEditorButton.vue";
export default {
  components: {GraphEditorButton},
  props: {
    currentPage: {
      required: true,
      type: Number
    },
    pagesCount: {
      required: true,
      type: Number
    }
  }
}
</script>

<style lang="scss" scoped>

.ui-guide-controls {
  width: 100%;
  padding-bottom: 20px;
  position: relative;
}

.ui-guide-controls__top {
  display: flex;
  justify-content: center;
  font-weight: bold;
  color: #7d838f;
  margin-bottom: 10px;
}

.ui-guide-controls__navigation {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-basis: 100%;
}

.ui-guide-controls__navigation-btn {
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
}

.ui-guide-controls__bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.ui-guide-controls__aside {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  position: absolute;
  right: 5%;

  @media screen and (max-width: 1000px) {
    margin-top: 10px;
    margin-left: initial;
    position: relative;
    right: initial;

    &>* {
      width: 100%;
    }
  }
}

</style>