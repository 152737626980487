/**
 * Возвращает position offset для ноды с id nodeId в списке нод
 * @param nodeId
 * @param nodes
 */
export default (nodeId, nodes) => {
    if(typeof nodeId !== "string" || typeof nodes !== "object") {
        console.error('Wrong params');
        return;
    }

    const mainNode = nodes[nodeId];

    if(!mainNode) return;

    return Object.entries(nodes).reduce((acc, [key, node]) => {

        if(node.id !== nodeId) {
            acc[key] = [
                node.position[0] - mainNode.position[0],
                node.position[1] - mainNode.position[1]
            ];
        }

        return acc;
    }, {});
};