<template>
  <li
      class="available-nodes-ui__list-item"
      @click="$emit('addNode', type)"
  >
    <img
        class="available-nodes-ui__list-icon"
        :src="availableNode.icon"
        alt="node icon"
    >
    <div class="available-nodes-ui__list-info">
      <span class="available-nodes-ui__list-name"> {{availableNode.name}} </span>
      <span
          class="available-nodes-ui__list-description"
          v-html="availableNode.description"
      />
    </div>
  </li>
</template>

<script>
export default {
  props: {
    availableNode: {
      required: true,
      type: Object
    },
    type: {
      required: true,
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>

.available-nodes-ui__list-item {
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
  cursor: pointer;

  & * {
    -webkit-user-drag: none;
    user-select: none;
  }

  &:hover {
    &::before {
      background-color: #ff6d5a;
    }
  }

  &::after {
    content: "";
    display: block;
    width: 90%;
    height: 1px;
    background-color: #dbdfe7;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &:last-child:after {
    display: none;
  }
}

.available-nodes-ui__list-icon {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

.available-nodes-ui__list-info {}

.available-nodes-ui__list-name {
  display: block;
  font-size: 14px;
  font-weight: bold;
  color: #555;
}

.available-nodes-ui__list-description {
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  color: #5e5e65;
}

</style>