<template>
  <ul class="ui-options">
    <li
        class="ui-options__item"
        title="Zoom in (+)"
        @click="$emit('changeScale', 1)"
    >
      <component :is="icons['iconPlus']" class="ui-options__item-icon" />
    </li>
    <li
        class="ui-options__item space"
        title="Zoom out (-)"
        @click="$emit('changeScale', -1)"
    >
      <component :is="icons['iconMinus']" class="ui-options__item-icon" />
    </li>
    <li
          class="ui-options__item"
          title="Focus (F)"
          @click="$emit('centerPosition')"
      >
        <component :is="icons['iconFocus']" class="ui-options__item-icon" />
      </li>
    <template v-if="!intoNode">
      <li
        class="ui-options__item"
        :class="{active: options.gridBinding}"
        title="Bind (B)"
        @click="$emit('changeGridBinding', !options.gridBinding)"
      >
          <component :is="icons['iconSteps']" class="ui-options__item-icon" />
      </li>
      <li
          class="ui-options__item space"
          title="Info (I)"
          @click="$emit('info')"
      >
        <component :is="icons['iconInfo']" class="ui-options__item-icon" />
      </li>
      <li
          class="ui-options__item space"
          :class="{disabled: !revertIsAvailable}"
          title="Return (Ctrl + Z)"
          @click="tryEmitRevert"
      >
        <component :is="icons['iconBack']" class="ui-options__item-icon" />
      </li>

      <li
          class="ui-options__item"
          :class="{disabled: !copyCutIsAvailable}"
          title="Copy (Ctrl + C)"
          @click="tryEmitCopy"
      >
        <component :is="icons['iconCopy']" class="ui-options__item-icon" />
      </li>

      <li
          class="ui-options__item"
          :class="{disabled: !copyCutIsAvailable}"
          title="Cut (Ctrl + X)"
          @click="tryEmitCut"
      >
        <component :is="icons['iconCut']" class="ui-options__item-icon" />
      </li>

      <li
          class="ui-options__item space"
          :class="{disabled: !pasteIsAvailable}"
          title="Insert (Ctrl + V)"
          @click="tryEmitPaste"
      >    
        <component :is="icons['iconPaste']" class="ui-options__item-icon" />
      </li>
      <li
          class="ui-options__item"
          :class="{active: moveIsActive}"
          title="Editor mode (M)"
          @click="tryEditorMode"
      >    
        <component :is="icons['iconMoveConnection']" class="ui-options__item-icon" />
      </li>
    </template>
  </ul>
</template>

<script>
import iconsVue from "../../mixins/iconsVue.js";

export default {
  mixins: [iconsVue],
  props: {
    options: {
      required: true,
      type: Object
    },
    snapshots: {
      required: false,
      type: Array
    },
    selectedNodes: {
      required: false,
      type: Object
    },
    copyData: {
      required: false,
      type: Object
    },
    moveConnection: {
      required: false,
      type: Boolean
    },
    intoNode: {
      type: Boolean
    }
  },
  computed: {
    revertIsAvailable() {
      return this.snapshots && this.snapshots.length;
    },
    pasteIsAvailable() {
      return this.copyData && Object.keys(this.copyData).length > 0;
    },
    copyCutIsAvailable() {
      return this.selectedNodes && Object.keys(this.selectedNodes).length > 0
    },
    moveIsActive: {
      caches: false,
      get(){
        return this.moveConnection
      }
    },
  },
  methods: {
    tryEmitRevert() {
      if(!this.revertIsAvailable) return;

      this.$emit('revert');
    },
    tryEmitCopy() {
      if(!this.copyCutIsAvailable) return;

      this.$emit('copy');
    },
    tryEmitCut () {
      if(!this.copyCutIsAvailable) return;

      this.$emit('cut');
    },
    tryEmitPaste() {
      if(!this.copyData) return;

      this.$emit('paste');
    },
    tryEditorMode() {
      this.$emit('setEditorMode');
    },
  },
}
</script>


<style lang="scss" scoped>

.ui-options {
  padding: 0;
  list-style: none;
  display: flex;
}

.ui-options__item {
  margin-right: 5px;
  border: 1px solid #666;
  border-radius: 3px;
  background-color: #fff;
  width: 38px;
  height: 38px;
  position: relative;
  pointer-events: all !important;
  padding: 3px;

  &.disabled {
    opacity: .3;
    cursor: not-allowed;

    &:hover {
      transform: none;
    }
  }

  &.space {
    margin-right: 25px;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    transform: scale(1.05);
  }
}

.ui-options__item-icon {
  width: 65%;
  height: 65%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  fill: #666;
}

.ui-options__item.active {
  border-color: #db7468;

  & .ui-options__item-icon {
    fill: #db7468;
  }
}

</style>