import iconAdd from '../assets/iconsVue/iconAdd.vue'
import iconBack from '../assets/iconsVue/iconBack.vue'
import iconCopy from '../assets/iconsVue/iconCopy.vue'
import iconCross from '../assets/iconsVue/iconCross.vue'
import iconCut from '../assets/iconsVue/iconCut.vue'
import iconDelete from '../assets/iconsVue/iconDelete.vue'
import iconDeleteConnection from '../assets/iconsVue/iconDeleteConnection.vue'
import iconEdit from '../assets/iconsVue/iconEdit.vue'
import iconFocus from '../assets/iconsVue/iconFocus.vue'
import iconGroupEdit from '../assets/iconsVue/iconGroupEdit.vue'
import iconHotkey from '../assets/iconsVue/iconHotkey.vue'
import iconInfo from '../assets/iconsVue/iconInfo.vue'
import iconKeys from '../assets/iconsVue/iconKeys.vue'
import iconMinus from '../assets/iconsVue/iconMinus.vue'
import iconMouse from '../assets/iconsVue/iconMouse.vue'
import iconNode from '../assets/iconsVue/iconNode.vue'
import iconOutput from '../assets/iconsVue/iconOutput.vue'
import iconPaste from '../assets/iconsVue/iconPaste.vue'
import iconPlus from '../assets/iconsVue/iconPlus.vue'
import iconSearch from '../assets/iconsVue/iconSearch.vue'
import iconSteps from '../assets/iconsVue/iconSteps.vue'
import iconUngroup from '../assets/iconsVue/iconUngroup.vue'
import iconMoveConnection from '../assets/iconsVue/iconMoveConnection.vue'
import iconReplacement from '../assets/iconsVue/iconReplacement.vue'

const icons = {
  iconAdd,
  iconBack,
  iconCopy,
  iconCross,
  iconCut,
  iconDelete,
  iconDeleteConnection,
  iconEdit,
  iconFocus,
  iconGroupEdit,
  iconHotkey,
  iconInfo,
  iconKeys,
  iconMinus,
  iconMouse,
  iconNode,
  iconOutput,
  iconPaste,
  iconPlus,
  iconSearch,
  iconSteps,
  iconUngroup,
  iconMoveConnection,
  iconReplacement,
}
export default{
  data: () => ({
    icons
  })
}