/**
 * Делает глубокое копирование объектов/массивов
 *
 * @param {(object|array)} obj
 * @returns {object}
 */
const deepCopy = (obj) => {
    if(typeof obj !== "object") {
        console.error('Cant make deep copy, wrong params');
        return;
    }

    if(Array.isArray(obj)) {
        return obj.reduce((acc, value) => {

            if(typeof value === "object") {
                acc.push(deepCopy(value))
            } else {
                acc.push(value);
            }

            return acc;
        }, []);
    }

    return Object.entries(obj).reduce((acc, [property, value]) => {
        if(typeof value === "object") {
            acc[property] = deepCopy(value);
        } else {
            acc[property] = value;
        }

        return acc;
    }, {});
};

/**
 * @param {function} fn
 * @returns {object}
 */
export const executedParamsToFn = (fn) => (obj) => {
    if(typeof obj === "function") {
        const objFnResult = obj();
        return typeof objFnResult === "object" ? fn(objFnResult) : {};
    } else {
        return fn(obj);
    }
};


const stupidDeepCopy = (obj) => {
    if(typeof obj !== "object") {
        console.error('Cant make deep copy, wrong params');
        return;
    }

    return JSON.parse(JSON.stringify(obj));
}

export default stupidDeepCopy;