<template>
  <div class="replace-node">
    <div class="replace-node__title" v-if="title">{{title}}</div>
    <div class="replace-node__label" v-if="label">{{ label }}</div>
    <div class="replace-node__actions">
      <button class="replace-node__btn replace-node__btn-purple" @click="$emit('emitPopup', true)">{{btn_event}}</button>
      <button class="replace-node__btn replace-node__btn-grey" @click="$emit('emitPopup', false)">{{btn_cancel}}</button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'GraphEditorPopup',
    props: {
      label: {
        type: String
      },
      title: {
        type: String
      },
      btn_event: {
        type: String
      },
      btn_cancel: {
        type: String
      }
    }
  }
</script>

<style lang="scss" scoped>
  .replace-node {
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    z-index: 10;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 0 50px 0 rgba(136, 152, 170, 1);

    &__title {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      font-size: 1.25rem;
      font-weight: 500;
      letter-spacing: 0.0125em;
      line-height: 2rem;
      word-break: break-all;
      margin-bottom: 20px;
    }

    &__label {
      margin-bottom: 15px;
    }

    &__actions{
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 15px; 
    }

    &__btn{
        color:#fff;
        padding: 5px 8px;
        border-radius: 5px;

        &-grey{
          background-color: #9e9e9e !important;
          border-color: #9e9e9e !important;
        }
        &-purple{
          background-color: #7B4DFF !important;
          border-color: #7B4DFF !important;
        }
      }
  }
</style>