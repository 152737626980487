/**
 * Возвращает тип для узла
 * @param {object} node
 * @param {object} availableNodes
 */
export default (node, availableNodes) => {
    if(typeof node !== "object" || typeof availableNodes !== "object" || !(node.type in availableNodes)) {
        console.error("Cant get node type, wrong params");
        return;
    }

    const originalType = availableNodes[node.type];

    if(typeof originalType === "undefined") {
        console.error("Cant get node type, wrong original type");
        return;
    }

    const overwritten = node['overwrittenNodeType'] || {};

    return {
        ...availableNodes[node.type],
        ...overwritten
    };
}