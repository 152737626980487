<template>
  <graph-editor-ui-guide-col-wrapper>
    <graph-editor-ui-guide-col
        v-for="(col, index) in cols"
        :key="index"
        v-bind="col"
    />
  </graph-editor-ui-guide-col-wrapper>
</template>

<script>
import GraphEditorUiGuideColWrapper from "../GraphEditorUiGuideColWrapper.vue";
import GraphEditorUiGuideCol from "../GraphEditorUiGuideCol.vue";

export default {
  components: {
    GraphEditorUiGuideCol,
    GraphEditorUiGuideColWrapper
  },
  data() {
    return {
      cols: [
        {
          icon: 'iconNode',
          title: 'Extending the connection from the node',
          description: 'To connect two nodes, you need to click on the output of the node and extend the connection to the input of the other node and release, after which they will connect'
        },
        {
          icon: 'iconOutput',
          title: 'When adding a node',
          description: 'You can connect two nodes by adding one of them. Just call up the menu and move the node to the output of an existing node, after which it will appear with a connection.'
        },
      ],
    };
  },
}
</script>

<styles></styles>