/**
 * Min и max одновременно для числа
 * @param {number} value
 * @param {number} min
 * @param {number} max
 * @return {number}
 */
export default (value, min, max) => {
    if(typeof value !== "number" || typeof min !== "number" || typeof max !== "number") {
        console.error('Cant clamp value, wrong params');
        return;
    }

    return Math.max(
        Math.min(value, max),
        min
    );
}