import {MAX_SNAPSHOTS_COUNT} from "../config/constants.js";

import deepCopy from "../helpers/deepCopy.js";
import clamp from "../helpers/math/clamp.js";

import copyNodesWithConnections from "../helpers/workflow/copyNodesWithConnections.js";
import nodeCanBeCopied from "../helpers/workflow/nodes/nodeCanBeCopied.js";
import shiftNodesPosition from "../helpers/workflow/nodes/shiftNodesPosition.js";


export default {
    data() {
        return {
            snapshots: [],
            snapshotsLockCounter: 0,
            copyData: null,
            searchNodesIsOpen: false,
        };
    },
    computed: {
        canBeCopiedNodesCount() {
            return Object.values(this.selectedNodes).filter(node => nodeCanBeCopied(node, this.fullAvailableNodes)).length;
        },
        scaledPosition() {
            return [
                this.position[0] / this.rightOptions.scale,
                this.position[1] / this.rightOptions.scale,
            ];
        },
        rootSize() {
            if(!this.nodesMounted) {
                return [0,0];
            }

            return [
                this.$refs.root.clientWidth,
                this.$refs.root.clientHeight
            ];
        },
    },
    methods: {
        setScale(newScale) {
            if(!newScale) {
                console.error('Cant set scale, wrong params');
                return;
            }

            if(newScale === 0) {
                console.error('Cant set scale, zero number');
                return;
            }

            this.$emit('update:options', {
                ...this.rightOptions,
                scale: clamp(newScale, this.rightOptions.minScale, this.rightOptions.maxScale),
            });
        },
        takeSnapshot() {
            if(this.snapshotsLockCounter !== 0) return;

            this.snapshots.push({
                nodes: deepCopy(this.nodes),
                connections: deepCopy(this.connections),
            });

            if(this.snapshots.length > MAX_SNAPSHOTS_COUNT) {
                const snapshotsMaxCountDiff = Math.abs(this.snapshots.length - MAX_SNAPSHOTS_COUNT);
                this.snapshots.splice(0, snapshotsMaxCountDiff);
            }
        },
        deleteLastSnapshot() {
            this.snapshots.pop();
        },
        revertSnapshot() {
            if(!this.snapshots.length) return;

            const lastSnapshot = this.snapshots.splice(this.snapshots.length-1, 1)[0];

            this.$emit('update:nodes', lastSnapshot.nodes);

            this.$nextTick(() => {
                this.$emit('update:connections', lastSnapshot.connections)
            });

            // send to admin
            this.$emit('stepBefore');
        },
        lockSnapshots() {
            this.snapshotsLockCounter++;
        },
        unlockSnapshots() {
            this.snapshotsLockCounter = Math.max(0, this.snapshotsLockCounter - 1);
        },
        forceUnlockSnapshots() {
            this.snapshotsLockCounter = 0;
        },
        copySelectedNodesWithConnections() {
            if(this.canBeCopiedNodesCount === 0) return;

            this.copyData = {
                nodes: deepCopy(this.selectedNodes),
                connections: deepCopy(this.connections),
                oldPosition: [...this.position],
                oldScale: this.rightOptions.scale
            };
            this.$emit("copy", this.copyData);
        },
        cutNodesWithConnections() {
            if(this.canBeCopiedNodesCount === 0) return;

            this.copyData = {
                nodes: deepCopy(this.selectedNodes),
                connections: deepCopy(this.connections)
            };

            this.$emit("cut", this.copyData);

            this.deleteSelectedNodesWithConnections();
        },
        pasteCopiedNodesWithConnections() {
            if(!this.copyData) return;

            this.takeSnapshot();

            const copiedWorkflow = copyNodesWithConnections(this.copyData.nodes, this.copyData.connections, this.fullAvailableNodes);

            const scaleDifference = this.rightOptions.scale + this.copyData.oldScale;
            const positionOffset = [
                (this.position[0] - this.copyData.oldPosition[0]) * scaleDifference,
                -(this.position[1] - this.copyData.oldPosition[1]) * scaleDifference
            ];
            shiftNodesPosition(positionOffset, copiedWorkflow.nodes);

            this.$emit('paste', copiedWorkflow);

            const updatedNodes = {
                ...this.nodes,
                ...copiedWorkflow.nodes,
            };

            const updatedConnections = {
                ...this.connections,
                ...copiedWorkflow.connections,
            };

            this.deselectAll()

            this.$emit('update:nodes', updatedNodes);

            this.$nextTick(() => {
                this.$emit('update:connections', updatedConnections);
            });
        },
        searchNode() {
            this.searchNodesIsOpen
                ? this.closeSearchPanelNodes()
                : this.openSearchPanelNodes()
        },
        openSearchPanelNodes(){
            this.searchNodesIsOpen = true
        },
        closeSearchPanelNodes(){
            this.searchNodesIsOpen = false
        },
    },
}