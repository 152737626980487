<template>
  <div
      class="wrapper"
      @mousemove="move"
  >
    <canvas
        ref="canvas"
    />
  </div>
</template>

<script>
import createPlane from "../helpers/primitives/createPlane.js";
import createBuffersWithAttrs from "../helpers/createGlState.js";
import createProgram from "../helpers/createProgram.js";

import sizes from "../mixins/sizes.js";
import gl from "../mixins/gl.js";
import {vertexShader, fragmentShader} from "../shaders/background.js";

export default {
  mixins: [
      sizes,
      gl
  ],
  props: {
    position: Array,
    cellSize: Number,
    scale: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      gl: null,
      program: null,
      glState: null,
      pointSize: 4,
      mousePosition: [.5, .5],
    }
  },
  watch: {
    position: {
      immediate: true,
      handler(newVal) {
        if(!this.gl) return;

        this.glState.setUniform('u_offset', (location) => this.gl.uniform2f(location, newVal[0], newVal[1]));

        this.draw();
      }
    },
    scale: {
      immediate: true,
      handler(newVal) {
        if(!this.gl) return;

        this.glState.setUniform('u_scale', (location) => this.gl.uniform1f(location, newVal));

        this.draw();
      },
    }
  },
  methods: {
    draw() {
      this.clear();
      this.gl.drawElements(this.gl.TRIANGLES, this.glState.getCount(), this.gl.UNSIGNED_SHORT, 0);
    },
    clear() {
      if(!this.gl) {
        console.error('Cant clear canvas');
        return;
      }

      this.gl.clearColor(1,1,1,1);
      this.gl.clear(this.gl.COLOR_BUFFER_BIT);
    },
    resize() {
      this.setCanvasSizesByParent(this.gl);

      if(!this.glState) return;

      this.glState.setUniform('u_resolution', (location) => this.gl.uniform2f(location, this.gl.canvas.width, this.gl.canvas.height));
      this.draw();
    },
    initializeGlWithStuff() {
      this.gl = this.createContext(this.$refs.canvas);

      if(!gl) throw new Error('Background: cant get Webgl context');

      this.setCanvasSizesByParent(this.gl);

      this.program = createProgram(this.gl, vertexShader, fragmentShader);
      this.gl.useProgram(this.program);

      this.glState = createBuffersWithAttrs(this.gl, this.program, createPlane());

      this.glState.setAttributes();
      this.glState.setUniform('u_resolution', (location) => this.gl.uniform2f(location, this.gl.canvas.width, this.gl.canvas.height));
      this.glState.setUniform('u_color', (location) => this.gl.uniform3f(location, 228/255, 228/255, 228/255));
      this.glState.setUniform('u_cell', (location) => this.gl.uniform1f(location, this.cellSize));
      this.glState.setUniform('u_size', (location) => this.gl.uniform1f(location, this.pointSize));
      this.glState.setUniform('u_mouse', (location) => this.gl.uniform2f(location, -100, -100));
      this.glState.setUniform('u_scale', (location) => this.gl.uniform1f(location, this.scale));
      this.glState.setUniform('u_offset', (location) => this.gl.uniform2f(location, this.position[0], this.position[1]));
    },
    move(e) {
      this.mousePosition[0] = e.offsetX / e.target.clientWidth;
      this.mousePosition[1] = 1 - e.offsetY / e.target.clientHeight;

      this.glState.setUniform('u_mouse', (location) => {
        this.gl.uniform2f(location, this.mousePosition[0], this.mousePosition[1])
      });

      this.draw();
    }
  },
  async mounted() {
    this.initializeGlWithStuff();
    this.draw();

    window.addEventListener('resize', this.resize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

canvas {
  position: absolute;
  left: 0;
  top: 0;
}
</style>