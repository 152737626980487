/**
 * Округляет координаты
 * @param {Array<number>} coordinates
 * @param {number} roundTo
 */
export default (coordinates, roundTo) => {
    if(!Array.isArray(coordinates) || coordinates.length !== 2 || typeof roundTo !== "number") {
        console.error('Cant round coords, wrong params');
        return;
    }

    if(roundTo === 0) {
        console.error('Cant round coords by zero');
        return;
    }

    return [
        Math.round(coordinates[0]/roundTo)*roundTo,
        Math.round(coordinates[1]/roundTo)*roundTo,
    ];
};