<template>
  <div class="available-nodes-ui">
    <graph-editor-button-ui
        class="available-nodes-ui__add"
        @click="$emit('open')"
    >
      Add node
    </graph-editor-button-ui>
    <div
        class="available-nodes-ui__panel"
        :class="{open}"
    >
      <graph-editor-search-ui
          v-model.trim="search"
      />

      <div
          class="available-node-ui__lists"
          @wheel.stop
      >
        <ul
            class="available-nodes-ui__categories"
            v-if="uiInfo && !search.length"
        >
          <li
              class="available-nodes-ui__category"
              :class="{open: categoryData.open}"
              v-for="(categoryData, categoryName) in uiInfo"
              :key="categoryName"
          >
            <div
                class="available-nodes-ui__category-name available-nodes-ui__panel-row"
                @click="categoryData.open = !categoryData.open"
            >
              {{categoryName}}
            </div>
            <div
                class="available-nodes-ui__category-body"
            >
              <ul
                  class="available-nodes-ui__list"
              >
                <graph-editor-available-node
                    class="available-nodes-ui__panel-row"
                    draggable="true"
                    v-for="(node, type) in categoryData.nodes"
                    :key="type"
                    :available-node="node"
                    :type="type"
                    @addNode="$emit('addNode', $event)"
                    @dragstart.native="$emit('dragStartNode', {type, node, event: $event})"
                />
              </ul>
            </div>
          </li>
        </ul>
        <ul
            class="available-nodes-ui__list"
            v-else
        >
          <graph-editor-available-node
              class="available-nodes-ui__panel-row"
              draggable="true"
              v-for="(node, type) in (search ? filteredAvailableNodes : this.fullAvailableNodes)"
              :key="type"
              :available-node="node"
              :type="type"
              @addNode="$emit('addNode', $event)"
              @dragstart.native="$emit('dragStartNode', {type, node, event: $event})"
          />
        </ul>
      </div>

      <div
        class="available-nodes-ui__not-found"
        v-if="!neededNodesIsExists"
    >
      <component :is="icons['iconNotFound']" 
          class="available-nodes-ui__not-found-icon"
          ref="notFoundIcon" />
          
      <div class="available-nodes-ui__not-found-text">
        No available nodes found
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import iconsVue from "../../mixins/iconsVue.js";

import GraphEditorButtonUi from "./utils/GraphEditorButton.vue";
import GraphEditorSearchUi from "./GraphEditorSearchUi.vue";
import GraphEditorAvailableNode from "./GraphEditorAvailableNode.vue";

import getCategorizedAvailableNodes from "../../helpers/workflow/available-nodes/getCategorizedAvailableNodes.js";
import filterAvailableNodesByName from "../../helpers/workflow/available-nodes/filterAvailableNodesByName.js";

export default {
  mixins: [iconsVue],
  components: {GraphEditorAvailableNode, GraphEditorSearchUi, GraphEditorButtonUi},
  props: {
    open: {
      type: Boolean,
      required: true
    },
    availableNodes: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      uiInfo: null,
      search: '',
    };
  },
  watch: {
    availableNodes: {
      handler() {
        this.getPreparedForUiInfo();
      },
      immediate: true
    }
  },
  methods: {
    getPreparedForUiInfo() {
      const categorizedNodes = getCategorizedAvailableNodes(this.availableNodes);

      if(categorizedNodes) {

        this.uiInfo = Object.entries(categorizedNodes).reduce((acc, pair) => {
          if(pair[0] === "Base Nodes") acc[pair[0]] = {
            open: false,
            nodes: pair[1]
          }
          return acc;
        }, {});

      } else {
        this.uiInfo = null;
      }
    },
  },
  computed: {
    filteredAvailableNodes() {
      if(!this.search || !this.availableNodes) return {};

      return filterAvailableNodesByName(this.search, this.availableNodes);
    },
    neededNodesIsExists() {
      if(this.search.length) {

        return Object.keys(this.filteredAvailableNodes).length > 0;
      }

      return Object.keys(this.availableNodes).length > 0;
    }
  },
}
</script>

<style lang="scss" scoped>
.available-nodes-ui {}

.available-nodes-ui__add {
  white-space: nowrap;
  position: absolute;
  right: 30px;
  top: 20px;
  z-index: 10;
}

.available-nodes-ui__panel {
  width: 20%;
  min-width: 350px;
  height: 100%;
  background-color: #fff;
  position: absolute;
  z-index: 15;
  right: 0;
  top: 0;
  transform: translateX(100%);
  transition: transform .3s;
  will-change: transform;
  border-left: 1px solid #dbdfe7;
  cursor: default;

  &.open {
    transform: translateX(0);
  }
}

.available-node-ui__lists {
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.available-nodes-ui__list {
  padding: 0;
}

.available-nodes-ui__categories {
  display: block;
  padding: 0;
}

.available-nodes-ui__category {
  border-bottom: 1px solid #dbdfe7;
}

.available-nodes-ui__category.open  .available-nodes-ui__category-body {
  display: block;
}

.available-nodes-ui__category.open .available-nodes-ui__category-name {
  border-bottom: 1px solid #dbdfe7;

  &::after {
    transform: translate(0, -20%) rotate(-45deg);
  }
}

.available-nodes-ui__category-body {
  display: none;
}

.available-nodes-ui__category-name {
  display: block;
  font-size: 17px;
  font-weight: bold;
  color: #5e5e65;
  padding: 15px 5%;
  position: relative;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-top: 2px solid #ff6d5a;
    border-right: 2px solid #ff6d5a;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%) rotate(135deg);
    transition: transform .2s;
  }
}

.available-nodes-ui__not-found {
  width: 100%;
  padding: 0 5%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.available-nodes-ui__not-found-icon {
  width: 90px;
  height: 90px;
  position: relative;
  stroke: #7d838f;
}

.available-nodes-ui__not-found-text {
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 14px;
  color: #7d838f;
}

</style>

<style lang="scss">

.available-nodes-ui__panel-row {
  position: relative;
  padding-left: 30px;
  padding-right: 10px;

  &:hover {
    background-color: #F7FAFC;
  }
}

</style>