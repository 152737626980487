<template>
  <div
      class="loader"
      :class="{visible}"
      @mousedown.stop
  ></div>
</template>

<script>

export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    }
  },
}

</script>

<style scoped lang="scss">

@keyframes rotation {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.loader {
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background-color: rgba(255,255,255,.9);
  visibility: hidden;
  opacity: 0;
  will-change: visibility, opacity;
  transition: visibility .6s, opacity .6s;

  &::before {
    content: "";
    display: block;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 7px solid transparent;
    border-top-color: #ff6d5a;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    animation: rotation 1.3s infinite ease-in-out;
  }

  &.visible {
    visibility: visible;
    opacity: 1;
  }
}

</style>

