/**
 * Возвращает bool, если точка пересекла коробку
 * @param point
 * @param box
 */
export default (point, box) => {
    if(!point || !box) {
        console.error('Cant calculate point In Box, wrong params');
        return;
    }

    const minBoxX = Math.min(box[0][0], box[1][0]);
    const maxBoxX = Math.max(box[0][0], box[1][0]);

    const minBoxY = Math.min(box[0][1], box[1][1]);
    const maxBoxY = Math.max(box[0][1], box[1][1]);

    return point[0] >= minBoxX && point[0] <= maxBoxX && point[1] >= minBoxY && point[1] <= maxBoxY;
}