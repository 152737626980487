<template>
  <div
      class="ui-guide-page scroll"
      ref="pageRoot"
  >
    <div
        class="ui-guide-page__body"
        ref="pageBody"
    >
      <component :is="component"></component>
    </div>
    <h4
        class="ui-guide-page__title"
        :style="{
          fontSize: `${titleSize}px`
        }"
        ref="title"
    >
      {{currentTitle}}
    </h4>
  </div>
</template>

<script>
import {GUIDE_CHAR_PRINT_TIME, GUIDE_MAX_CHAR_SIZE} from "../../../config/constants.js";

export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    component: {
      required: true,
    }
  },
  watch: {
    title: {
      handler() {
        clearInterval(this.titleInterval);

        this.calculateTitleSize();

        this.currentTitle = '';
        this.startAppendChars();
      },
      immediate: true
    },
  },
  data() {
    return {
      isMounted: false,
      currentTitle: '',
      titleInterval: null,
      titleSize: 0,
    }
  },
  methods: {
    async startAppendChars() {
      const charsGenerator = (function* () {
        for(let i = 0; i < this.title.length; i++) {
          yield this.title[i];
        }
      }).bind(this)();

      this.titleInterval = setInterval(async () => {
        const generatorResult = charsGenerator.next()

        if(generatorResult.done) {
          clearInterval(this.titleInterval);
          return;
        }

        this.currentTitle += generatorResult.value;
      }, GUIDE_CHAR_PRINT_TIME);
    },
    calculateTitleSize() {
      if(!this.isMounted || !this.$refs.pageRoot) return 0;

      const pageWidth = this.$refs.pageRoot.clientWidth;

      this.titleSize = Math.min(pageWidth * 1.2   / this.title.length, GUIDE_MAX_CHAR_SIZE);
    },
  },
  mounted() {
    this.isMounted = true;

    this.calculateTitleSize();
    window.addEventListener('resize', this.calculateTitleSize.bind(this));
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateTitleSize.bind(this));
  }
}
</script>

<style lang="scss" scoped>

.ui-guide-page {
  flex-grow: 1;
  padding: 50px 3% 0;
  user-select: text;

  &.scroll {
    overflow-y: scroll;
  }
}

.ui-guide-page__title {
  position: absolute;
  left: 2%;
  bottom: 15%;
  text-transform: uppercase;
  color: #ececec;
  line-height: 1;
  user-select: none;
  pointer-events: none;
  z-index: 1;

  @media screen and (max-width: 1000px) {
    bottom: 25%;
  }
}

.ui-guide-page__body {
  position: relative;
  z-index: 2;
}

</style>