<template>
  <div class="nodes-ui">
    <div
        class="nodes-ui__panel"
        :class="{open}"
    >
      <div class="nodes-ui__search-block">
        <graph-editor-search-ui
            v-model.trim="search"
            @clearSearch="clearSearch('node')"
            ref="search"
            searchByEnter
        />
        <graph-editor-search-ui
            v-model.trim="searchByConnections"
            @clearSearch="clearSearch('connections')"
            ref="searchByConnections"
            searchPlaceholder="Search by connections..."
            notBorderTop
            searchByEnter
        />
      </div>

      <div
          class="node-ui__lists"
          v-if="(search.length && filteredNodes.length) || (searchByConnections.length && filteredNodes.length)"
          @wheel.stop
      >
        <ul class="nodes-ui__categories">
          <li class="nodes-ui__category">
            <div class="nodes-ui__category-body">
              <ul class="nodes-ui__list">
                <li
                    class="nodes-ui__panel-row"
                    v-for="node in filteredNodes"
                    :key="node.id"
                    @click="searchNode(node)"
                >
                  <p class="nodes-ui__list-item">
                    {{node.db_id}} - {{node.displayName}}
                  </p>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div
          class="nodes-ui__not-found"
          v-else
      >
        <component :is="icons['iconNotFound']"
                   class="nodes-ui__not-found-icon"
                   ref="notFoundIcon" />

        <div class="nodes-ui__not-found-text">
          No available nodes found
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iconsVue from "../../mixins/iconsVue.js";
import GraphEditorSearchUi from "./GraphEditorSearchUi.vue";

import filterNodesOnCanvas from "../../helpers/workflow/search-nodes-on-canvas/filterNodesOnCanvas.js";

export default {
  name: "GraphEditorSearchNodesUi",
  mixins: [iconsVue],
  components: {GraphEditorSearchUi},
  props: {
    open: {
      type: Boolean,
      required: true
    },
    nodes: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      search: '',
      searchByConnections: '',
    };
  },
  methods: {
    searchNode(node){
      Object.entries(this.nodes)
          .forEach(node => node[1].selected = false)

      node.selected = true

      this.$emit('setPosition', [node.position[0], -node.position[1]])
    },
    clearSearch(type){
      type === 'node'
        ? this.$refs.searchByConnections.resetValue()
        : this.$refs.search.resetValue()
    }
  },
  computed: {
    filteredNodes() {
      if((!this.search && !this.searchByConnections) || !this.nodes) return {};

      return this.search.length
        ? filterNodesOnCanvas(this.search, this.nodes)
        : filterNodesOnCanvas(this.searchByConnections, this.nodes, true)
    },
  }
}
</script>

<style lang="scss" scoped>
.nodes-ui {
  &__add {
    white-space: nowrap;
    position: absolute;
    right: 30px;
    top: 20px;
    z-index: 10;
  }

  &__panel {
    width: 20%;
    min-width: 350px;
    height: 100%;
    background-color: #fff;
    position: absolute;
    z-index: 15;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: transform .3s;
    will-change: transform;
    border-left: 1px solid #dbdfe7;
    cursor: default;

    &.open {
      transform: translateX(0);
    }
  }

  &__list {
    padding: 0;

    &-item {
      display: flex;
      padding-top: 15px;
      padding-bottom: 15px;
      position: relative;
      cursor: pointer;

      & * {
        -webkit-user-drag: none;
        user-select: none;
      }

      &:hover {
        &::before {
          background-color: #ff6d5a;
        }
      }

      &::after {
        content: "";
        display: block;
        width: 90%;
        height: 1px;
        background-color: #dbdfe7;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &:last-child:after {
        display: none;
      }
    }
  }

  &__categories {
    display: block;
    padding: 0;
  }

  &__category {
    border-bottom: 1px solid #dbdfe7;
  }

  &__category  &__category-body {
    display: block;
  }

  &__category &__category-name {
    border-bottom: 1px solid #dbdfe7;

    &::after {
      transform: translate(0, -20%) rotate(-45deg);
    }
  }

  &__category-body {
    display: none;
  }

  &__category-name {
    display: block;
    font-size: 17px;
    font-weight: bold;
    color: #5e5e65;
    padding: 15px 5%;
    position: relative;
    cursor: pointer;

    &::after {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      border-top: 2px solid #ff6d5a;
      border-right: 2px solid #ff6d5a;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translate(0, -50%) rotate(135deg);
      transition: transform .2s;
    }
  }

  &__not-found {
    width: 100%;
    padding: 0 5%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  &__not-found-icon {
    width: 90px;
    height: 90px;
    position: relative;
    stroke: #7d838f;
  }

  &__not-found-text {
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 14px;
    color: #7d838f;
  }
  &__panel-row {
    position: relative;
    padding-left: 30px;
    padding-right: 10px;

    &:hover {
      background-color: #F7FAFC;
    }
  }
}

.node-ui__lists {
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>