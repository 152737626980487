<template>
  <svg
      class="connection"
      :width="width"
      :height="height"
      :style="{
        left: `${minPoint[0]}px`,
        top: `${minPoint[1]}px`
      }"
  >
    <marker
        :id="id"
        v-if="withMarker"
        markerWidth="7"
        markerHeight="7"
        refX="7"
        refY="3.5"
        orient="auto"
        :style="{
          fill: color
        }"
    >
      <polygon
          points="0 0, 7 3.5, 0 7"
      />
    </marker>
    <path
        :marker-end="`url(#${id})`"
        :d="currentLine"
        :style="{
          stroke: color
        }"
        @mouseenter="$emit('mouseenter')"
        @mouseleave="$emit('mouseleave')"
        @click="$emit('click')"
    />
    <path
        :d="currentLine"
        :style="{
          stroke: 'transparent',
          strokeWidth: '20px'
        }"
        @mouseenter="$emit('mouseenter')"
        @mouseleave="$emit('mouseleave')"
        @click="$emit('click')"
    />
  </svg>
</template>

<script>
import {ARROW_CURVE_LINE_DISTANCE} from "../config/constants.js";

import calculateDistance from "../helpers/math/calculateDistance.js";

export default {
  props: {
    from: {
      type: Array,
      required: true
    },
    to: {
      type: Array,
      required: true
    },
    withMarker: {
      type: Boolean,
      required: false,
      default: true,
    },
    color: {
      type: String,
      required: false
    },
    positionConnect: {
      type: Object
    },
    movement: {
      type: Object
    },
    endNodeId: {
      type: Object
    },
    isLineCreated: {
      type: Boolean
    }
  },
  data() {
    return {
      ARROW_CURVE_LINE_DISTANCE,
      gap: 150,
      id: (new Date()).getTime().toString() + Math.random().toString(),
      typeLine: '',
      coordinatesForSave: [],
      statesPosition: {
        new: null,
        old: null
      },
      minOffsetNode: 20,
      isMovement: false,
    };
  },
  watch: {
    typeLine(newTypeLine, oldTypeLine){
      this.$emit('setTypeLine', newTypeLine)      
      
      //set y = 0 
      if(oldTypeLine === 'straightLine' && (newTypeLine === 'frontLine' || newTypeLine === 'backLine')) this.positionConnect.y = 0    

      if(newTypeLine === undefined){
        if(oldTypeLine === 'frontLine') this.positionConnect.x = -(this.middlePosition[0] - this.correctedEnd[0] + this.minOffsetNode)
        if(oldTypeLine === 'backLine') this.positionConnect.x = this.correctedStart[0] - this.middlePosition[0] + this.minOffsetNode
      }
    },
    coordinatesForSave(newPosition, oldPosition){
    
      if(JSON.stringify(oldPosition) === JSON.stringify(newPosition)) return
      if(this.movement?.isMoveToConnects || this.movement?.nodeIsMoved) {
        
        this.statesPosition = {
          new: newPosition,
          old: oldPosition
        }
      }
    },
    movement: {
      handler(newPosition, oldPosition){
        if(newPosition?.isMoveToConnects || newPosition?.nodeIsMoved) this.isMovement = true
        else this.isMovement = false

        if(newPosition?.isMoveToConnects === oldPosition?.isMoveToConnects && newPosition?.nodeIsMoved === oldPosition?.nodeIsMoved) return
        if(!newPosition?.isMoveToConnects && !newPosition?.nodeIsMoved && (oldPosition?.isMoveToConnects || oldPosition?.nodeIsMoved) && (JSON.stringify(this.statesPosition.new) !== JSON.stringify(this.statesPosition.old))) {

          this.$emit('lineConnections', {
            lineOffset: {
              x: Number(this.positionConnectX.toFixed(3)),
              y: Number(this.positionConnectY.toFixed(3))
            },
            fromId: this.from[2],
            toId: this.to[2],
            typeLine: this.typeLine
          })
        }

        this.statesPosition.old = this.statesPosition.new
      },
      deep: true
    }
  },
  computed: {
    positionConnectX(){
      if(this.positionConnect?.x && this.positionConnect.x !== 0) return this.positionConnect.x
      return 0
    },
    positionConnectY(){
      if(this.positionConnect?.y) return this.positionConnect.y
      return 0
    },
    leftPostionLine(){
      if(this.positionConnectX && this.positionConnect.x < 0) return this.positionConnect.x;
      return 0;
    },
    topPostionLine(){
      if(this.positionConnectY && this.positionConnect.y < 0) return this.positionConnect.y;
      return 0;
    },
    minPoint() {
      return [
        Math.min(this.from[0], this.to[0]) - this.halfGap + this.leftPostionLine,
        Math.min(this.from[1], this.to[1]) - this.halfGap + this.topPostionLine
      ];
    },
    maxPoint() {
      return [
        Math.max(this.from[0], this.to[0]) + this.halfGap,
        Math.max(this.from[1], this.to[1]) + this.halfGap
      ];
    },
    width() {
      return this.maxPoint[0] - this.minPoint[0] + this.gap + Math.abs(this.positionConnectX);
    },
    height() {
      return this.maxPoint[1] - this.minPoint[1] + Math.abs(this.positionConnectY);
    },
    halfGap() {
      return this.gap/2;
    },
    correctedStart() {
      return [
        this.from[0] - this.minPoint[0],
        this.from[1] - this.minPoint[1],
      ];
    },
    correctedEnd() {
      return [
        this.to[0] - this.minPoint[0],
        this.to[1] - this.minPoint[1],
      ];
    },
    middlePosition() {
      return [
        (this.correctedStart[0] + this.correctedEnd[0]) / 2,
        (this.correctedStart[1] + this.correctedEnd[1]) / 2,
      ];
    },
    distanceBetweenPoints() {
      return calculateDistance(this.minPoint, this.maxPoint);
    },
    middlePositionLine(){
      return {
        x: this.middlePosition[0] + this.positionConnectX,
        y: this.middlePosition[1] + this.positionConnectY
      }
    },
    // xDirection() {
    //   return Math.abs(this.xDistance) / - this.xDistance;
    // },
    // xDistance() {
    //   return this.correctedStart[0] - this.correctedEnd[0];
    // },
    // yDirection() {
    //   return Math.abs(this.yDistance) / - this.yDistance;
    // },
    // yDistance() {
    //   return this.correctedStart[1] - this.correctedEnd[1];
    // },
    // curveLine() {
    //   return `
    //   M${this.correctedStart[0]} ${this.correctedStart[1]}
    //   L${this.correctedStart[0] + 10} ${this.correctedStart[1]}
    //   C${this.correctedStart[0] + (100 * this.xDirection)} ${this.correctedStart[1]}
    //   ${this.correctedEnd[0] - (120 * this.xDirection)} ${this.correctedEnd[1]}
    //   ${this.correctedEnd[0] - (20 * this.xDirection)} ${this.correctedEnd[1]}
    //   L${this.correctedEnd[0]} ${this.correctedEnd[1]}
    //   `;
    // },
    straightLine() {
      this.coordinatesForSave = [
        [this.correctedStart[0], this.correctedStart[1]],
        [this.middlePositionLine.x, this.correctedStart[1]],
        [this.middlePositionLine.x, this.correctedEnd[1]],
        [this.correctedEnd[0], this.correctedEnd[1]]
      ]
      return this.buildLineConnect
    },
    backLine() {
      this.coordinatesForSave = [
        [this.correctedStart[0], this.correctedStart[1]],
        [this.correctedStart[0] + this.minOffsetNode, this.correctedStart[1]],
        [this.correctedStart[0] + this.minOffsetNode, this.middlePositionLine.y],
        [this.middlePositionLine.x, this.middlePositionLine.y],
        [this.middlePositionLine.x, this.correctedEnd[1]],
        [this.correctedEnd[0], this.correctedEnd[1]]
      ]
      return this.buildLineConnect
    },
    frontLine() {
      this.coordinatesForSave = [
        [this.correctedStart[0], this.correctedStart[1]],
        [this.middlePositionLine.x, this.correctedStart[1]],
        [this.middlePositionLine.x, this.middlePositionLine.y],
        [this.correctedEnd[0] - this.minOffsetNode, this.middlePositionLine.y],
        [this.correctedEnd[0] - this.minOffsetNode, this.correctedEnd[1]],
        [this.correctedEnd[0], this.correctedEnd[1]]
      ]
      return this.buildLineConnect
    },
    currentLine() {
      let localTypeLine
      const typesLine = {
        'backLine' : this.backLine,
        'straightLine': this.straightLine,
        'frontLine': this.frontLine,
      }  

      if(
        this.middlePositionLine.x <= this.correctedEnd[0] - this.minOffsetNode && 
        this.middlePositionLine.x >= this.correctedStart[0] + this.minOffsetNode &&
        this.from[0] + this.minOffsetNode*2 <= this.to[0] 
      ){
        localTypeLine = 'straightLine';
      }else if(
        this.middlePositionLine.x - this.minOffsetNode <= this.correctedStart[0] &&
        this.middlePositionLine.x + this.minOffsetNode <= this.correctedEnd[0]
      ){
        localTypeLine = 'backLine'
      }else if(
        this.middlePositionLine.x - this.minOffsetNode >= this.correctedStart[0] &&
        this.middlePositionLine.x + this.minOffsetNode >= this.correctedEnd[0] ||
        !this.endNodeId || !this.typeLine
      ){
        localTypeLine = 'frontLine'
      }

      if(this.isMovement || this.isLineCreated) this.typeLine = localTypeLine
      return typesLine[localTypeLine]
    },
    buildLineConnect(){
      return this.coordinatesForSave.reduce((string, coordinatie, i) => {
          if(i === 0) string += `M${coordinatie.join(' ')} `
          else string += `L${coordinatie.join(' ')} `

          return string
        }, '')
    },
  },
}

</script>

<style scoped lang="scss">

.connection {
  position: absolute;
  fill: none;
  pointer-events: none;

  & path {
    stroke-width: 2px;
    stroke: #7d838f;
    pointer-events: visiblePainted;
  }

  & marker {
    fill: #7d838f;
  }
}

</style>