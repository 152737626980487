/**
 * Возвращает исходящие соединения из ноды
 * @param {string} nodeId
 * @param {Object} connections
 */
export default (nodeId, connections) => {
    if(typeof nodeId !== "string" || typeof connections !== "object") {
        console.error('Cant get outgoing connections, wrong params');
        return;
    }

    if(!(nodeId in connections)) {
        console.error('Cant get outgoing connections, nodeId doesnt exists');
        return;
    }

    return connections[nodeId];
}