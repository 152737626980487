<template>
  <div class="ui-guide-col">
    <component :is="icons[icon]" class="ui-guide-col__icon" />
    <h6 class="ui-guide-col__title">{{title}}</h6>
    <p class="ui-guide-col__description">{{description}}</p>
  </div>
</template>

<script>

import iconsVue from "../../../mixins/iconsVue.js";
export default {
  mixins: [iconsVue],
  props: {
    icon: {
      required: true,
    },
    title: {
      required: true,
      type: String,
    },
    description: {
      required: true,
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-guide-col {
  font-size: 0;
  line-height: 1;
}

.ui-guide-col__icon {
  width: 80px;
  height: 80px;
  margin-bottom: 35px;
  fill: #ff6d5a;
}

.ui-guide-col__title {
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.ui-guide-col__description {
  font-size: 15px;
  color: #7d838f;
  line-height: 1.5;
}

</style>