/**
 * Возвращает знаковую единичку.
 * @param {Number} val
 * @return {number}
 */
export default (val) => {
    if(typeof val !== "number") {
        console.error('Cant normalize 1f value');
        return;
    }

    return val === 0 ? 0 : Math.abs(val)/val;
}