import normalize1f from "../helpers/math/normalize1f.js";

export default {
    methods: {
        deleteKeyHandler(event) {
            if(!event) return;

            if(event.key === 'Delete') {
                event.preventDefault();
                this.deleteSelectedNodesWithConnections();
            }
        },
        handleMoveShortcuts(event) {
            if(!event) return;

            const moveVector = [0,0];

            switch (event.key) {

                case 'ArrowUp':
                    moveVector[1] += 1;
                    event.preventDefault();
                    break;
                case 'ArrowDown':
                    moveVector[1] -= 1;
                    event.preventDefault();
                    break;
                case 'ArrowLeft':
                    moveVector[0] += 1;
                    event.preventDefault();
                    break;
                case 'ArrowRight':
                    moveVector[0] -= 1;
                    event.preventDefault();
                    break;

            }

            this.position = [
                this.position[0] - moveVector[0] * this.rightOptions.cellSize * 3,
                this.position[1] + moveVector[1] * this.rightOptions.cellSize * 3
            ];
        },
        handleRevertSnapshot(event) {
            if(!event.ctrlKey || event.keyCode !== 90) return;

            this.revertSnapshot();
        },
        handleZoomKeys(event) {
          if(!event || event.keyCode !== 107 && event.keyCode !== 109) return;

          let direction = 0;

          switch (event.keyCode) {
              case 107:
                  direction = 1;
                  break;
              case 109:
                  direction = -1
                  break;
          }

          this.setScale(this.rightOptions.scale + direction * this.rightOptions.scaleStep);
        },
        handleBindingsToggleKey(event) {
          if(event.keyCode !== 66) return;

          event.preventDefault();

          this.$emit('update:options', {
              ...this.rightOptions,
              gridBinding: !this.rightOptions.gridBinding
          });
        },
        handleFocusKey(event) {
            if(event.keyCode !== 70) return;

            event.preventDefault();

            this.centerOnNodeMassCenter();
        },
        handleInfoKey(event) {
            if(event.keyCode !== 73) return;

            this.toggleGuideVisibility();
        },
        handleCopyKey(event) {
            if(event.keyCode !== 67 || !event.ctrlKey || Object.keys(this.selectedNodes).length === 0) return;

            this.copySelectedNodesWithConnections();
        },
        handleCutKey(event) {
            if(event.keyCode !== 88 || !event.ctrlKey || Object.keys(this.selectedNodes).length === 0) return;

            this.cutNodesWithConnections();
        },
        handlePasteKey(event) {
            if(event.keyCode !== 86 || !event.ctrlKey) return;

            this.pasteCopiedNodesWithConnections();
        },
        handleGroupKey(event) {
            if(event.keyCode !== 71 || Object.keys(this.selectedNodes).length === 0) return;

            event.preventDefault();

            this.groupSelectedNodes();
        },
        handleMoveConnects(event){
            if(event.keyCode === 77) this.isKeyM = !this.isKeyM
        },
        handleSearchNode(){
            if(event.keyCode !== 70 || !event.ctrlKey) return;

            this.searchNode()
        },
        keyboardDownHandler(event) {
            if(!event || this.rightOptions.loading || !this.editorInFocus || this.previewOptions || this.isChangeDisplayName) return;

            this.handleInfoKey(event);

            if(this.guideIsOpen) return;

            this.handleMoveShortcuts(event);
            this.deleteKeyHandler(event);
            this.handleZoomKeys(event);
            this.handleBindingsToggleKey(event);
            this.handleFocusKey(event);
            this.handleRevertSnapshot(event);
            this.handleCopyKey(event);
            this.handleCutKey(event);
            this.handlePasteKey(event);
            this.handleGroupKey(event);
            this.handleMoveConnects(event);
            this.handleSearchNode(event);
        },
        // keyboardUpHandler(event){
        //     if(!event || this.rightOptions.loading || !this.editorInFocus || this.previewOptions) return;

        //     this.handleInfoKey(event);

        //     if(this.guideIsOpen) return;

        //     this.handleMoveConnects(event, 'up');
        // },
        wheelHandler(event) {
            if(!event || this.rightOptions.loading || this.guideIsOpen || this.previewOptions) return;

            this.wheelChangeScale(event);
            this.wheelMove(event);
        },
        wheelMove(event) {
          if(!event || event.ctrlKey) return;

          event.preventDefault();

          this.position = [
              this.position[0] + (event.shiftKey ? event.deltaY : event.deltaX),
              this.position[1] - event.deltaY * (1 - event.shiftKey)
          ];
        },
        wheelChangeScale(event) {
            if(!event || !event.ctrlKey) return;

            event.preventDefault();

            const direction = -normalize1f(event.deltaY);
            const softScale = .3
            const newScale = this.rightOptions.scale +  this.rightOptions.scaleStep * direction * softScale;
            this.setScale(newScale);
        },
    },
    mounted() {
        window.addEventListener('keydown', this.keyboardDownHandler);
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.keyboardDownHandler);
    }
}