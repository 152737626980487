import sortedNodesByPosition from "./sortedNodesByPosition.js";
import getNodePositionOffsets from "../../getNodePositionOffsets.js";

export default (nodes) => {
    if(typeof nodes !== "object") {
        console.error("Cant drop extra nodes position, wrong params");
        return;
    }

    if(Object.values(nodes).length === 0) {
        return nodes;
    }

    const firstNodeLocation = sortedNodesByPosition(nodes)[0];
    const offsets = getNodePositionOffsets(firstNodeLocation.id, nodes);

    Object.values(nodes).forEach(node => {
        node.position = offsets[node.id] ?? [0,0];
    });

    return nodes;
}