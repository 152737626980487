export const DEFAULT_CONTEXT = 'webgl';

export default {
    methods: {
        /**
         * Устанавливает контекст поумолчанию
         * @param {HTMLCanvasElement} canvas
         * @return WebGLRenderingContext
         */
        createContext(canvas) {
            return canvas.getContext(DEFAULT_CONTEXT, {
                antialias: true,
            });
        },
    }
}