/**
 * Возвращает дробную часть числа
 * @param {number} value
 * @returns {number}
 */
export default (value) => {
    if(typeof value !== "number") {
        console.error('Cant fract value wrong params');
        return;
    }

    const valueStr = value.toString();
    const dotIndex = valueStr.indexOf('.');

    return Number(valueStr.slice(dotIndex+1));
}