/**
 * Возвращает длину вектора
 * @param {Array} vector
 */
export default (vector) => {
    if(!Array.isArray(vector) || vector.length !== 2) {
        console.error('Cant calculate vector length, wrong params');
        return;
    }

    return Math.sqrt(
        Math.pow(vector[0], 2) + Math.pow(vector[1], 2)
    );
}