<template>
    <svg version="1.1" id="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="10 10  29 26" style="enable-background:new 10 10  29 26;" xml:space="preserve">
    <path d="M30.0242 10.4258C30.2925 10.1575 30.7275 10.1575 30.9958 10.4258L33.1909 12.6209C33.3652 12.7455 33.4788 12.9495 33.4788 13.1802C33.4788 13.3223 33.4357 13.4544 33.3617 13.564C33.3344 13.6079 33.3017 13.6493 33.2636 13.6875L30.9965 15.9545C30.7282 16.2228 30.2932 16.2228 30.0249 15.9545C29.7566 15.6862 29.7566 15.2512 30.0249 14.9829L31.1407 13.8672L20.4254 13.8672C20.046 13.8672 19.7384 13.5596 19.7384 13.1802C19.7384 12.8007 20.046 12.4931 20.4254 12.4931L31.12 12.4931L30.0242 11.3974C29.7559 11.1291 29.7559 10.6941 30.0242 10.4258Z" />
    <path d="M13.9762 19.603C13.9762 19.2236 13.6686 18.916 13.2892 18.916C12.9098 18.916 12.6022 19.2236 12.6022 19.603L12.6022 30.3181L11.4873 29.2032C11.219 28.9349 10.784 28.9349 10.5157 29.2032C10.2474 29.4715 10.2474 29.9065 10.5157 30.1748L12.7827 32.4419C12.8216 32.4807 12.8639 32.5139 12.9087 32.5415C13.0176 32.6141 13.1485 32.6564 13.2892 32.6564C13.5184 32.6564 13.7215 32.5442 13.8463 32.3716L16.0431 30.1748C16.3114 29.9065 16.3114 29.4715 16.0431 29.2032C15.7748 28.9349 15.3398 28.9349 15.0715 29.2032L13.9762 30.2984L13.9762 19.603Z" />
    <path d="M30.7309 19.6496H33.021V21.9397H30.7309V19.6496Z" />
    <path d="M22.0279 30.6421H19.7378V32.9322H22.0279V30.6421Z" />
    <path d="M27.5244 30.6421H25.2344V32.9322H27.5244V30.6421Z" />
    <path d="M33.021 25.1462H30.7309V27.4363H33.021V25.1462Z" />
    <path d="M30.7309 30.6421H33.021V32.9322H30.7309V30.6421Z" />
    <path d="M26.1842 22.5052C26.1842 22.1258 25.8766 21.8182 25.4972 21.8182C25.1178 21.8182 24.8102 22.1258 24.8102 22.5052V24.0527L17.2479 16.4905C16.9796 16.2222 16.5446 16.2222 16.2763 16.4905C16.008 16.7588 16.008 17.1938 16.2763 17.4621L23.8546 25.0404H22.2736C21.8942 25.0404 21.5866 25.348 21.5866 25.7274C21.5866 26.1068 21.8942 26.4144 22.2736 26.4144H25.4797C25.5432 26.4144 25.6046 26.4058 25.6629 26.3897C25.7836 26.3616 25.8982 26.3005 25.9923 26.2065C26.1553 26.0435 26.2193 25.8189 26.1842 25.6076V22.5052Z" />
  </svg>
</template>

<script>
  export default {
    name: "iconMoveConnection"
  }
</script>