<template>
  <svg version="1.1" id="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512" xml:space="preserve">
    <g>
      <g>
        <path
          d="M324.267,25.6H281.6c-2.355,0-4.267-1.911-4.267-4.267V4.267c0-2.355-1.911-4.267-4.267-4.267h-102.4
			c-2.355,0-4.267,1.911-4.267,4.267v17.067c0,2.355-1.911,4.267-4.267,4.267H128c-2.355,0-4.267,1.911-4.267,4.267v25.6
			c0,2.355,1.911,4.267,4.267,4.267h196.267c2.355,0,4.267-1.911,4.267-4.267v-25.6C328.533,27.511,326.622,25.6,324.267,25.6z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M401.067,42.667h-51.2c-2.355,0-4.267,1.911-4.267,4.267v25.6c0,2.355-1.911,4.267-4.267,4.267h-230.4
			c-2.355,0-4.267-1.911-4.267-4.267v-25.6c0-2.355-1.911-4.267-4.267-4.267H51.2c-2.355,0-4.267,1.911-4.267,4.267V448
			c0,2.355,1.911,4.267,4.267,4.267h85.333c2.355,0,4.267-1.886,4.267-4.241c-0.017-34.722-0.102-272.06-0.026-320.094
			c0.008-4.71,3.823-8.465,8.533-8.465h220.134c1.126,0,2.21,0.452,3.004,1.246l25.6,25.6c2.688,2.688,7.287,0.785,7.287-3.012
			V46.933C405.333,44.578,403.422,42.667,401.067,42.667z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M460.8,256H349.867c-2.355,0-4.267-1.911-4.267-4.267V140.8c0-2.355-1.911-4.267-4.267-4.267h-179.2
			c-2.355,0-4.267,1.911-4.267,4.267v366.933c0,2.355,1.911,4.267,4.267,4.267H460.8c2.355,0,4.267-1.911,4.267-4.267V260.267
			C465.067,257.911,463.155,256,460.8,256z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M457.788,231.646l-87.834-87.825c-2.688-2.688-7.287-0.785-7.287,3.012v87.834c0,2.355,1.911,4.267,4.267,4.267h87.834
			C458.564,238.933,460.476,234.334,457.788,231.646z" />
      </g>
    </g>
  </svg>
</template>

<script>
  export default {
    name: "iconPaste"
  }
</script>

<style>

</style>