export default (move, nodes) => {
    if(!Array.isArray(move) || typeof nodes !== "object") {
        console.error("Cant shift nodes positions, wrong params");
        return;
    }

    Object.values(nodes).forEach(node => {
       node.position[0] += move[0];
       node.position[1] += move[1];
    });

    return nodes;
}