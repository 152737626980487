export const INDICES_KEY = 'indices';
export const UV_KEY = 'uv';
export const POSITION_KEY = 'position';

/**
 * @typedef {Object} BufferDataObject
 * @property {number[]} position
 * @property {number[]} uv
 * @property {number[]} indices
 */

/**
 * @typedef {Object} BuffersObject
 * @property {number[]} position
 * @property {number[]} uv
 * @property {number[]} indices
 */

/**
 * Принимает на вход массивы, возвращает буфферы.
 * @param {WebGLRenderingContext} gl
 * @param {BufferDataObject} buffersData
 */
export const createBuffersFromObject = (gl, buffersData) => {
    let buffersObject = {};

    for(const [key, value] of Object.entries(buffersData)) {
        const isIndices = key === INDICES_KEY;

        const bufferType = isIndices ? gl.ELEMENT_ARRAY_BUFFER  : gl.ARRAY_BUFFER;
        const bufferData = isIndices ? new Uint16Array(value): new Float32Array(value);

        const buffer = gl.createBuffer();
        gl.bindBuffer(bufferType, buffer);
        gl.bufferData(bufferType, bufferData, gl.STATIC_DRAW);

        buffersObject[key] = buffer;
    }

    return buffersObject;
};

/**
 *
 * @param {WebGLRenderingContext} gl
 * @param {WebGLProgram} program
 * @param {WebGLBuffer} buffer
 * @return {function}
 */
const getPositionSetter = (gl, program, buffer) => {
    const location = gl.getAttribLocation(program, POSITION_KEY);
    gl.enableVertexAttribArray(location);

    return () => {
        gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
        gl.vertexAttribPointer(location, 3, gl.FLOAT, false, Float32Array.BYTES_PER_ELEMENT*3, 0);
    };
};

/**
 *
 * @param {WebGLRenderingContext} gl
 * @param {WebGLProgram} program
 * @param {WebGLBuffer} buffer
 * @return {function}
 */
const getUVSetter = (gl, program, buffer) => {
    const location = gl.getAttribLocation(program, UV_KEY);
    gl.enableVertexAttribArray(location);

    return () => {
        gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
        gl.vertexAttribPointer(location, 2, gl.FLOAT, false, Float32Array.BYTES_PER_ELEMENT*2, 0);
    };
};

/**
 *
 * @param {WebGLRenderingContext} gl
 * @param {WebGLProgram} program
 * @param {WebGLBuffer} buffer
 * @return {function}
 */
const getIndicesSetter = (gl, program, buffer) => {
    return () => {
        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffer)
    };
};

/**
 * Принимает на вход массивы, возвращает сеттеры для аттрибутов.
 * @param {WebGLRenderingContext} gl
 * @param {WebGLProgram} program
 * @param {BufferDataObject} buffersData
 */
export default (gl, program, buffersData) => {
    let data = buffersData;
    let buffers = createBuffersFromObject(gl, data);
    let attribSetters = [];
    let uniforms = {};

    const addSetters = () => {
        for(const [key, value] of Object.entries(buffers)) {
            switch (key) {
                case POSITION_KEY:
                    attribSetters.push( getPositionSetter(gl, program, value) );
                    break;
                case UV_KEY:
                    attribSetters.push( getUVSetter(gl, program, value) );
                    break;
                case INDICES_KEY:
                    attribSetters.push( getIndicesSetter(gl, program, value) )
                    break;
            }
        }
    };
    addSetters();

    return {
        getSetters: () => attribSetters,
        getCount: () => buffersData[INDICES_KEY].length,
        getBuffers: () => buffers,
        getData: () => data,
        setAttributes: () => attribSetters.forEach(setter => setter()),

        /**
         * Кеширует местоположение uniform-переменных, а также принимает функцию в которую передаётся uniform-location,
         * чтобы можно было удобно обработать.
         * @param {string} key
         * @param {function} uniformSetter
         */
        setUniform: (key, uniformSetter) => {
            if(!key || !uniformSetter) {
                console.error('Cant set uniform wrong params');
                return;
            }

            if(!(key in uniforms)) {
                uniforms[key] = gl.getUniformLocation(program, key);
            }

            uniformSetter(uniforms[key]);
        },
    };
};