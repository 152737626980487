export const MAX_SNAPSHOTS_COUNT = 20;
export const ARROW_CURVE_LINE_DISTANCE = 400;

export const GUIDE_CHAR_PRINT_TIME = 50;
export const GUIDE_MAX_CHAR_SIZE = 140;

export const DEFAULT_OPTIONS = Object.freeze({
    scale: 1,
    maxScale: 1.5,
    minScale: .5,
    scaleStep: .1,
    cell: true,
    cellSize: 65/2,
    gridBinding: true,
    ui: true,
    loading: false,
    mountFocus: true,
});