<template>
  <svg version="1.1" id="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" viewBox="0 0 503.607 503.607" style="enable-background:new 0 0 503.607 503.607;" xml:space="preserve">
    <g>
      <g>
        <g>
          <path d="M184.656,285.379c4.636,0,8.393-3.758,8.393-8.393v-33.574c0-4.636-3.758-8.393-8.393-8.393h-83.934
				c-4.636,0-8.393,3.758-8.393,8.393v33.574c0,4.636,3.758,8.393,8.393,8.393H184.656z M109.115,251.805h67.148v16.787h-67.148
				V251.805z" />
          <path d="M100.721,218.231h83.934c4.636,0,8.393-3.758,8.393-8.393v-33.574c0-4.636-3.758-8.393-8.393-8.393h-83.934
				c-4.636,0-8.393,3.758-8.393,8.393v33.574C92.328,214.473,96.086,218.231,100.721,218.231z M109.115,184.657h67.148v16.787
				h-67.148V184.657z" />
          <path d="M436.459,0h-33.574c-4.636,0-8.393,3.758-8.393,8.393v394.383c0,27.786-22.628,50.47-50.361,50.47H218.229
				c-27.755,0-50.361-22.606-50.361-50.361v-0.001c9.277,0,16.787-7.51,16.787-16.787v-16.786c4.636,0,8.393-3.758,8.393-8.393
				c0-4.636-3.758-8.393-8.393-8.393v-18.372c21.063-7.71,41.967-42.303,41.967-73.955V100.721c0-9.277-7.51-16.787-16.787-16.787
				h-16.787V16.787C193.049,7.51,185.539,0,176.262,0h-67.148c-9.277,0-16.787,7.51-16.787,16.787v67.148H75.541
				c-9.277,0-16.787,7.51-16.787,16.787v159.475c0,31.475,20.941,66.187,41.967,73.942v18.385c-4.636,0-8.393,3.758-8.393,8.393
				c0,4.636,3.758,8.393,8.393,8.393v16.786c0,9.277,7.51,16.787,16.787,16.787v0.001c0,55.542,45.18,100.721,100.721,100.721
				h125.902c55.527,0,100.721-45.265,100.721-100.83V8.393C444.852,3.758,441.095,0,436.459,0z M109.115,16.787h67.148v67.148
				h-67.148V16.787z M75.541,260.197V100.721h25.18h83.934h25.18v159.475c0,27.673-21.035,58.743-33.567,58.754
				c-0.002,0-0.005,0-0.007,0h-67.148c-0.002,0-0.005,0-0.007,0C96.649,318.939,75.541,287.683,75.541,260.197z M117.508,335.738
				h50.361v16.787h-50.361V335.738z M167.869,369.311v16.786h-50.361v-16.786H167.869z M428.066,402.776
				c0,46.3-37.685,84.044-83.934,84.044H218.229c-46.271,0-83.934-37.664-83.934-83.934h16.787
				c0,37.026,30.122,67.148,67.148,67.148h125.902c37.013,0,67.148-30.209,67.148-67.257V16.787h16.787V402.776z" />
        </g>
      </g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
  export default {
    name: "iconOutput"
  }
</script>

<style>

</style>