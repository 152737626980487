<template>
  <svg viewBox="10 10  29 26" fill="none"  version="1.1" id="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px">
    <path d="M11.4961 12.2518C11.4961 11.8344 11.8344 11.4961 12.2518 11.4961H17.6106C18.028 11.4961 18.3663 11.8344 18.3663 12.2518C18.3663 12.6692 18.028 13.0075 17.6106 13.0075H13.0076V17.6107C13.0076 18.0281 12.6693 18.3664 12.2519 18.3664C11.8345 18.3664 11.4962 18.0281 11.4962 17.6107V12.2636L11.4961 12.2518Z" fill="black"/>
    <path d="M16.2137 16.2137H18.9618V18.9618H16.2137V16.2137Z" fill="black"/>
    <path d="M16.2137 22.626H18.9618V25.374H16.2137V22.626Z" fill="black"/>
    <path d="M18.9618 29.0382H16.2137V31.7863H18.9618V29.0382Z" fill="black"/>
    <path d="M22.626 16.2137H25.374V18.9618H22.626V16.2137Z" fill="black"/>
    <path d="M25.374 22.626H22.626V25.374H25.374V22.626Z" fill="black"/>
    <path d="M22.626 29.0382H25.374V31.7863H22.626V29.0382Z" fill="black"/>
    <path d="M31.7863 16.2137H29.0382V18.9618H31.7863V16.2137Z" fill="black"/>
    <path d="M29.0382 22.626H31.7863V25.374H29.0382V22.626Z" fill="black"/>
    <path d="M31.7863 29.0382H29.0382V31.7863H31.7863V29.0382Z" fill="black"/>
    <path d="M11.4962 35.7481V30.3893C11.4962 29.9719 11.8345 29.6336 12.2519 29.6336C12.6693 29.6336 13.0076 29.9719 13.0076 30.3893V34.9924H17.6107C18.0281 34.9924 18.3664 35.3307 18.3664 35.7481C18.3664 36.1655 18.0281 36.5038 17.6107 36.5038H12.2519C11.9389 36.5038 11.6703 36.3135 11.5556 36.0423C11.5173 35.9518 11.4962 35.8524 11.4962 35.7481Z" fill="black"/>
    <path d="M29.6336 12.2519C29.6336 11.8345 29.9719 11.4962 30.3893 11.4962H35.7474C35.8223 11.4962 35.8953 11.5071 35.9636 11.5273C36.276 11.6201 36.5038 11.9094 36.5038 12.2519V17.6107C36.5038 18.0281 36.1655 18.3664 35.7481 18.3664C35.3307 18.3664 34.9924 18.0281 34.9924 17.6107V13.0076H30.3893C29.9719 13.0076 29.6336 12.6693 29.6336 12.2519Z" fill="black"/>
    <path d="M30.3893 34.9924C29.9719 34.9924 29.6336 35.3307 29.6336 35.7481C29.6336 36.1655 29.9719 36.5038 30.3893 36.5038H35.7481C35.7742 36.5038 35.8 36.5025 35.8254 36.4999C36.2064 36.4612 36.5038 36.1394 36.5038 35.7481V30.3893C36.5038 29.9719 36.1655 29.6336 35.7481 29.6336C35.3307 29.6336 34.9924 29.9719 34.9924 30.3893V34.9924H30.3893Z" fill="black"/>
  </svg>
</template>

<script>
  export default {
    name: "iconFocus"
  }
</script>

<style>

</style>