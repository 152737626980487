/**
 * Pure
 * Возвращает список категорий для доступных нод.
 * @param availableNodes
 */
export default (availableNodes) => {
    if(!availableNodes) {
        console.error('Cant get categories, wrong params');
        return;
    }

    return Array.from(
        new Set(Object.values(availableNodes).filter(availableNodes => availableNodes.category).map(availableNode => availableNode.category))
    );
}