import compareNodePositions from "./compareNodePositions.js";

/**
 * Возвращает узлы в порядке возрастания позиции
 * @param {Object} nodes
 */
export default (nodes) => {
    if(typeof nodes !== "object") {
        console.error("Cant sort nodes");
        return;
    }

    return Object.values(nodes).sort(compareNodePositions);
}