<template>
  <div class="preview-wrapper">
    <component
        ref="preview"
        :is="GraphEditor"
        :nodes="nodes"
        :connections="connections"
        :availableNodes="availableNodes"
        :options.sync="previewOptions"
        :previewScale="initialScale"
    />
  </div>
</template>

<script>
import GraphEditor from "./../GraphEditor.vue";

export default {
  props: {
    nodes: {
      type: Object,
      required: true
    },
    connections: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    parentScale: {
      type: Number,
      required: true,
    },
    availableNodes: {
      type: Object,
      required: true
    },
  },
  watch: {
    options: {
      handler(value) {
        const scale = value.scale ?? .5;

        this.initialScale = scale;
        this.previewOptions = {
          ...value,
          scale: scale * this.parentScale,
        };
      },
      immediate: true,
    }
  },
  data() {
    return {
      GraphEditor: GraphEditor,
      previewOptions: {},
      initialScale: 0,
    }
  },
}
</script>

<style lang="scss" scoped>
.preview-wrapper {
  pointer-events: none !important;
  width: 100%;
  height: 100%;
}
</style>

<style lang="scss">
.preview-wrapper {
  & * {
    pointer-events: none !important;
  }

  & svg, & path {
    pointer-events: none !important;
  }
}
</style>