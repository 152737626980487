<template>
  <svg viewBox="10 9  29 28" fill="none"  version="1.1" id="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3721 28.3037C14.6217 27.0535 16.471 26.769 17.9936 27.45L21.4379 24.0041L17.1415 21.5225C15.8646 22.4103 14.1388 22.5386 12.7029 21.7092C10.7215 20.5647 10.0426 18.0299 11.1866 16.0475C12.3306 14.0652 14.8642 13.3859 16.8457 14.5305C18.5333 15.5052 19.276 17.4885 18.7524 19.2801L23.4485 21.9926L32.8974 12.5394C32.9825 12.4542 33.0976 12.406 33.218 12.4052L35.8255 12.3867C36.235 12.3838 36.4421 12.8791 36.1525 13.1688L25.911 23.415L38.478 30.6739C38.8085 30.8647 38.7744 31.3524 38.4207 31.4953L36.2659 32.3658C36.1352 32.4186 35.9875 32.4082 35.8654 32.3377L23.9004 25.4265L19.9878 29.3409C20.7882 30.8996 20.5358 32.8595 19.2308 34.1651C17.613 35.7837 14.9899 35.7837 13.3721 34.1651C11.7542 32.5466 11.7542 29.9223 13.3721 28.3037ZM24.2267 24.1114C24.4081 23.8443 24.3803 23.4774 24.1434 23.2404C23.9216 23.0185 23.5859 22.98 23.3249 23.125C23.2293 23.1832 23.1463 23.2657 23.0863 23.3698C22.8967 23.6984 23.007 24.1172 23.3327 24.3054C23.6376 24.4815 24.0231 24.3929 24.2267 24.1114ZM17.1722 19.5049C16.4074 20.8303 14.7138 21.2839 13.3897 20.5191C12.0656 19.7543 11.6116 18.0601 12.3764 16.7348C13.1412 15.4094 14.8348 14.9558 16.1589 15.7206C17.483 16.4854 17.9371 18.1796 17.1722 19.5049ZM18.2594 29.2755C19.3408 30.3574 19.3408 32.1115 18.2594 33.1933C17.1781 34.2752 15.4248 34.2752 14.3435 33.1933C13.2621 32.1115 13.2621 30.3574 14.3435 29.2755C15.4248 28.1937 17.1781 28.1937 18.2594 29.2755Z" fill="black"/>
  </svg> 

</template>

<script>
  export default {
    name: "iconCut"
  }
</script>

<style>

</style>