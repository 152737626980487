<template>
  <div
      v-if="isOk"
      class="node"
      :style="{
        transform: `translate3d(${node.position[0]}px, ${node.position[1]}px, 0)`
      }"
      @focusout="endRename"
  >
    <ul
        v-if="!intoNode"
        class="node__actions"
        v-show="node.selected"
    >
      <li
          v-if="canBeDeleted"
          class="node__action"
          @click.stop="deleteNode"
          title="Delete node"
      >
        <component :is="icons['iconDelete']" class="node__action-icon" />
      </li>
      <li
          class="node__action"
          title="Rename node"
          @click.stop="startRename"
      >
        <component :is="icons['iconEdit']" class="node__action-icon" />
      </li>
      <li
        v-if="!(node.type === 'groupInput' || node.type === 'groupOutput')"
        class="node__action"
        title="Replace node"
        @click="$emit('startReplaceNode', node)"
        >
        <component :is="icons['iconReplacement']" class="node__action-icon" />
      </li>
    </ul>
    <div
        class="node__body"
        :class="{
          'selected': node.selected, 
          'broken-node': brokenNode, 
          'warning-node': warningNode && !brokenNode,
        }"
        :style="{
          width: `${nodeWidth}px`,
          height: `${nodeHeight}px`,
          border: node.nodeColor ? `2px solid ${node.nodeColor} !important` : '',
        }"
        @mousedown.stop="$emit('mousedown', $event)"
        @mouseenter.stop="$emit('mouseenter', $event)"
        @mouseleave.stop="$emit('mouseleave', $event)"
        @dblclick="$emit('dblclick', $event)"
    >
      <div
          class="node__inner"
          :class="{subgroup}"
      >
        <div class="node__top">
          <img
              class="node__icon"
              :src="type.icon"
          />
          <ul
              v-if="subgroup"
              class="node__group-actions"
              @mousedown.stop
              @mouseup.stop
          >
            <li class="node__group-action">
              <button
                  class="node__group-action-button"
                  title="Редактировать"
                  @click="$emit('editGroup', subgroupContext)"
              >
                <component :is="icons['iconGroupEdit']" class="node__group-action-icon" />
              </button>
            </li>
            <li class="node__group-action">
              <button
                  class="node__group-action-button"
                  title="Разгруппировать"
                  @click="$emit('ungroup')"
              >
                <component :is="icons['iconUngroup']" class="node__group-action-icon" />
              </button>
            </li>
          </ul>
        </div>
        <div
            v-if="subgroup"
            class="node__subgroup-wrapper"
            @mousedown.stop
            @mouseup.stop
            @wheel.stop
            @keydown.stop
            @keyup.stop
        >
          <div
              class="node__subgroup"
          >
            <GraphEditorPreview
                :nodes.sync="subgroup.nodes"
                :connections.sync="subgroup.connections"
                :available-nodes="availableNodes"
                :options="{ui: false, mountFocus: false, scale: .25, cell: false}"
                :parentScale="this.scale"
            />
          </div>
        </div>
      </div>
      <ul class="node__inputs">
        <li
            v-if="type.input"
            :ref="`input-main`"
            data-name="main"
            class="node__input"
            :class="{disabled: !editorMode}"
        ></li>
      </ul>
      <ul class="node__outputs">
        <template v-if="!editorMode">
        <li
            v-for="(output, outputIndex) in outputs"
            :key="output"
            class="node__output disabled droppable"
            :class="{plus: outputIsPlus(output)}"
            :data-name="output"
            :ref="`output-${output}`"
        >
          <component :is="icons['iconAdd']" class="node__output-icon" />

          <span
              v-show="outputNames && outputNames[outputIndex] && (scale > minScale)"
              class="node__output-label"
          >
            {{ outputNames[outputIndex] }}
          </span>
        </li>
      </template>
      <template v-else>
        <li
            v-for="(output, outputIndex) in outputs"
            :key="output"
            class="node__output droppable"
            :class="{plus: outputIsPlus(output)}"
            :data-name="output"
            :ref="`output-${output}`"
            @mousedown.stop="startOutputDragging"
            @drop.prevent="$emit('dropNodeOutput', {
              event: $event,
              outputName: output,
              node
            })"
            @dragover.prevent
            @dragenter.prevent
            @mouseleave="$emit('outputMouseLeave')"
        >
          <component :is="icons['iconAdd']" class="node__output-icon" />

          <span
              v-show="outputNames && outputNames[outputIndex] && (scale > minScale)"
              class="node__output-label"
          >
            {{ outputNames[outputIndex] }}
          </span>
        </li>
      </template>
      </ul>
    </div>
    <div
        class="node__name"
        v-show="scale > minScale"
    >
      <div
          class="node__name-name"
          v-show="!changingDisplayName"
          @click="startRename"
      >
        <span
            :title="node.displayName"
        >
        {{ node.displayName }}
        </span>
        <span
            v-if="node.subtitle"
            class="node__subtitle"
        >
        {{ node.subtitle }}
      </span>
    </div>
    <span style="font-size: 12px;font-weight: 400;">{{ node?.db_id }}</span>
      <div
          class="node__name-input-wrapper"
          v-show="changingDisplayName"
      >
        <input
            ref="nameInput"
            type="text"
            v-model.trim="node.displayName"
            @keydown.enter="enterPress"
            @keyup.stop
        >
      </div>
    </div>
  </div>
</template>

<script>
import node from "../mixins/node.js";
import iconsVue from "../mixins/iconsVue.js";

import GraphEditorPreview from "./GraphEditorPreview.vue";

export default {
  components: {
    GraphEditorPreview
  },
  mixins: [node, iconsVue],
  computed: {
    brokenNode(){
      return this.node?.withOutInput || this.node?.unknownNodes
    },
    warningNode(){
      return this.node?.unselectedConditions
    }
  },
  props: {
    editorMode: {
      Boolean
    },
    intoNode: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>

.node {
  position: absolute;
  z-index: 4;
}

.node__actions {
  display: flex;
  padding: 0;
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(-100%);
}

.node__action {
  padding: 5px;
  cursor: pointer;
}

.node__action:hover {
  .node__action-icon {
    fill: #db7468;
  }
}

.node__action-icon {
  width: 15px;
  height: 15px;
  fill: #ababab;
}

.node__body {
  border: 2px solid #7d838f;
  border-radius: 8px;
  background-color: #fff;
  user-select: none;
  cursor: pointer;
  position: relative;
}

.node__inner {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;

  &.subgroup {
    display: flex;
    flex-direction: column;

    &>.node__top {
      width: 100%;
      flex-basis: 100%;
      max-height: 30px;
      align-items: center;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      border-bottom: 1px solid #5e5e65;
      background-color: #F7FAFC;
      position: relative;
      transform: none;
      left: 0;
      top: 0;

      &>.node__icon {
        position: static;
        width: 30px;
        height: 20px;
        margin-left: 5px;
        transform: none;
      }
    }
  }
}

.node__subgroup-wrapper {
  flex-grow: 1;
  pointer-events: none;
}

.node__subgroup {
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  pointer-events: none;
}

.selected {
  box-shadow: 0 0 0 8px #e8ebf1;
}

.node__top {
  display: flex;
  justify-content: space-between;
  width: 50%;
  height: 50%;
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.node__icon {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.node__group-actions {
  display: flex;
  height: 100%;
}

.node__group-action {
  height: 100%;
  position: relative;

  &:last-child::after {
    display: none;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 2;
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
    width: 1.5px;
    height: 60%;
    background: #ececec;
    display: block;
  }
}

.node__group-action-button {
  display: block !important;
  width: 45px;
  background-color: #f9975c;
  border: none;
  outline: none;
  height: 100%;
  position: relative;
  cursor: pointer;
  transition: background-color .2s;

  &:hover {
    background-color: #c0c3c9;
  }
}

.node__group-action-icon {
  max-width: 60%;
  max-height: 60%;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  fill: #ececec;
}

.node__inputs, .node__outputs {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  // height: 70%; // height of connect
  padding: 0;
  gap: 10px;
  list-style: none;
  position: absolute;
  left: -1px;
  top: 50%;
  z-index: 5;
  transform: translate(-50%, -50%);
}

.node__input {
  background-color: #7d838f;

  &:hover {
    background-color: #db7468;
  }
}

.node__input.disabled {
  background-color: #7d838f;
}

.node__input {
  flex-basis: 100%;
  width: 6px;
  max-height: 25px;
}

.node__outputs {
  left: 100%;
}

.node__output {
  width: 16px;
  max-width: 16px;
  box-sizing: border-box;
  position: relative;

  & svg {
    display: none;
  }

  &::before {
    content: "";
    display: block;
    width: 11px;
    height: 11px;
    background-color: #7d838f;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover::before {
    background-color: #db7468;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &::after {
    content: "";
    display: block;
    padding-top: 100%;
  }
}

.node__output.plus {
  background: #fff;
  border: 2px solid #7d838f;
  border-radius: 4px;

  &::before {
    display: none;
  }

  &:hover {
    border-color: #db7468;

    & svg {
      fill: #db7468;
    }
  }
}

.node__output.plus.disabled {
  background: #fff;
  border: 2px solid #7d838f;
  border-radius: 4px;

  &::before {
    display: none;
  }
}

.node__output.disabled {
  width: 16px;
  max-width: 16px;
  box-sizing: border-box;
  position: relative;

  & svg {
    display: none;
  }

  &::before {
    content: "";
    display: block;
    width: 11px;
    height: 11px;
    background-color: #7d838f;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    border-color: #7d838f;

    & svg {
      fill: #7d838f;
    }
  }
}

.node__output-label {
  font-size: 12px;
  font-weight: bold;
  text-transform: lowercase;
  padding-left: 10px;
  white-space: nowrap;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(100%, -50%);
  z-index: -1;
  pointer-events: none;
}

.node__output-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 70%;
  height: 70%;
  fill: #7d838f;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.node__output.plus .node__output-icon {
  display: block;
}

.node__name {
  min-width: 100%;
  font-weight: bold;
  font-size: 13px;
  text-align: center;
  position: absolute;
  left: 50%;
  bottom: -10px;
  white-space: nowrap;
  transform: translate(-50%, 100%);

  & .node__subtitle {
    font-size: 12px;
    font-weight: bold;
    text-transform: lowercase;
    color: #5e5e65;

    &::before {
      content: "(";
    }

    &::after {
      content: ")";
    }
  }
}

.node__name-name {
  cursor: pointer;

  &>span:first-child {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 115px;
  }
}

.node__name-input-wrapper {
  position: relative;
}

.node__name input {
  width: 150%;
  max-width: 150px;
  background: #fff;
  border: 1px solid #909399;
  padding: 2px 5%;
  font-size: 13px;
  font-weight: normal;
  outline: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
</style>

<style lang="scss">
.node__subgroup {
  svg, path {
    pointer-events: none !important;
  }
}

.broken-node{
  border: 2px solid #ff3203 !important;
}
.warning-node{
  border: 2px solid #ffc003 !important;
}
</style>