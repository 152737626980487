<template>
  <svg version="1.1" id="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
     y="0px"
     viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"
>
	<path d="M501.333,373.333h-96v-74.667c0-5.888-4.779-10.667-10.667-10.667h-96v-74.667c0-5.888-4.779-10.667-10.667-10.667h-96
			V128c0-5.888-4.779-10.667-10.667-10.667h-96V42.667C85.333,36.779,80.555,32,74.667,32h-64C4.779,32,0,36.779,0,42.667
			s4.779,10.667,10.667,10.667H64V128c0,5.888,4.779,10.667,10.667,10.667h96v74.667c0,5.888,4.779,10.667,10.667,10.667h96v74.667
			c0,5.888,4.779,10.667,10.667,10.667h96V384c0,5.888,4.779,10.667,10.667,10.667h96v74.667c0,5.888,4.779,10.667,10.667,10.667
			c5.888,0,10.667-4.779,10.667-10.667V384C512,378.112,507.221,373.333,501.333,373.333z"/>
</svg>

</template>

<script>
  export default {
    name: "iconSteps"
  }
</script>

<style>

</style>