import getCategories from "./getCategories.js";

export const DEFAULT_CATEGORY = 'Остальное';

/**
 * Возвращает сгруппированные по категориям доступные ноды
 */
export default (availableNodes) => {
    if(typeof availableNodes !== "object" || Array.isArray(availableNodes)) {
        console.error('Cant get categorized available nodes, wrong params');
        return;
    }

    const categories = getCategories(availableNodes);

    if(categories.length) {
        let result = {};

        result = Object.entries(availableNodes).reduce((acc, pair) => {
            const nodeType = pair[0];
            const node = pair[1];

            const category = node.category ?? DEFAULT_CATEGORY;

            if(!Object.hasOwnProperty.call(acc, category)) {
                acc[category] = {};
            }

            acc[category][nodeType] = node;

            return acc;
        }, {});

        return result;
    }

    return {};
}