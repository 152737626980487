import calculateVectorLength from "./calculateVectorLength.js";

/**
 *
 * @param {Array} vector1
 * @param {Array} vector2
 */
export default (vector1, vector2) => {
    if(!Array.isArray(vector1) || !Array.isArray(vector2)) {
        console.error('Cant calculate distance');
        return;
    }

    const vectorDiff = [
        Math.abs(vector1[0] - vector2[0]),
        Math.abs(vector1[1] - vector2[1]),
    ];

    return calculateVectorLength(vectorDiff);
}