import pointInBox from "../physics/collision/pointInBox.js";
import deepCopy from "../deepCopy.js";

/**
 * Pure
 * Перебирает список нод и устанаваливает для них selected - true, если они внутри короба
 * @param {Array} box
 * @param {Object} nodeList
 * @return {Object}
 */
export default (box, nodeList) => {
    if(!Array.isArray(box) || typeof nodeList !== "object") {
        console.error("Cant select collided nodes, wrong params");
        return;
    }

    const updatedNodeList = deepCopy(nodeList);

    for(const nodeKey in updatedNodeList) {
        const node = updatedNodeList[nodeKey];
        node.selected = pointInBox(node.position, box);
    }

    return updatedNodeList;
}