import deepCopy from "../deepCopy.js";

/**
 * Pure
 * Удаляет все пустые поля в connections
 */
export default (connectionList) => {
    if(typeof connectionList !== "object") {
        console.error('Cant clear connection list, wrong params');
        return;
    }

    const updatedConnections = deepCopy(connectionList);

    for(const startNodeId in updatedConnections) {
        const connectionsInfo = updatedConnections[startNodeId];

        if(!Object.keys(connectionsInfo).length) {
            delete updatedConnections[startNodeId];
        } else {
            for(const outputName in connectionsInfo) {
                const outputInfo = connectionsInfo[outputName];

                if(!outputInfo.length) {
                    delete connectionsInfo[outputName];
                }
            }

            if(!Object.keys(connectionsInfo).length) delete updatedConnections[startNodeId];
        }
    }
    

    return updatedConnections;
}