<template>
  <div class="editor-subgroup">
    <div class="editor-subgroup__top-line">
      <graph-editor-button
          class="editor-subgroup__close"
          @click="closeLastContext"
      >
        Close
      </graph-editor-button>
      <div class="editor-subgroup__path">
        <div class="editor-subgroup__path-title">Navigation</div>
        <ul class="editor-subgroup__path-list">
          <li
              class="editor-subgroup__path-item"
              @click="closeAllContexts"
          >
          Root nodes
          </li>
          <li
              class="editor-subgroup__path-item"
              v-for="(item, index) in subgroupContexts"
              @click="changeContextTo(index)"
          >
            {{item.name ?? "Неизвестно"}}
          </li>
        </ul>
      </div>
    </div>
    <component
        ref="subgroup"
        v-if="subgroupIsReady && currentSubgroupContext"
        :is="GraphEditor"
        :isSubgroup="true"
        :nodes.sync="currentSubgroupContext.grouped.nodes"
        :connections.sync="currentSubgroupContext.grouped.connections"
        :options.sync="subgroupOptions"
        :availableNodes="availableNodes"
        @subgroupSetContext="emitSubgroupSetContext"
        @moveNode="passEventToParent('moveNode', $event)"
        @addNode="passEventToParent('addNode', $event)"
        @deleteNode="passEventToParent('deleteNode', $event)"
        @selectNode="passEventToParent('selectNode', $event)"
        @deselectNode="passEventToParent('deselectNode', $event)"
        @startConnecting="passEventToParent('startConnecting', $event)"
        @connectNode="passEventToParent('connectNode', $event)"
        @deleteConnection="passEventToParent('deleteConnection', $event)"
        @deleteNodeConnections="passEventToParent('deleteNodeConnections', $event)"
        @deleteSelectedNodes="passEventToParent('deleteSelectedNodes', $event)"
        @nodeDblClick="passEventToParent('nodeDblClick', $event)"
        @paste="passEventToParent('paste', $event)"
        @copy="passEventToParent('copy', $event)"
        @cut="passEventToParent('cut', $event)"
        @group="passEventToParent('group', $event)"
        @ungroup="passEventToParent('ungroup', $event)"
        @replacementNode="passEventToParent('replacementNode',  $event)"
    />
        <!-- @methodName = emit name-->
        <!-- passEventToParent('emit name' + $event)  -->
  </div>
</template>

<script>
import GraphEditorButton from "./UI/utils/GraphEditorButton.vue";
import GraphEditor from "../GraphEditor.vue";

export default {
  components: {GraphEditorButton},
  props: {
    subgroupContexts: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    availableNodes: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      GraphEditor: GraphEditor,
      subgroupIsReady: false,
    }
  },
  watch: {
    currentSubgroupContext: {
      handler() {
        if(this.$refs.subgroup) {
          this.$refs.subgroup.$forceUpdate();
        }

        this.$nextTick(() => {
          this.subgroupIsReady = true;
        });
      },
      immediate: true,
    }
  },
  computed: {
    currentSubgroupContext() {
      return this.subgroupContexts[this.subgroupContexts.length - 1];
    },
    subgroupOptions: {
      get() {
        return {...this.options, mountFocus: true};
      },
      set(value) {
        this.$emit("subgroupOptionsUpdate", value);
      }
    },
    nodeIdPath() {
      return this.subgroupContexts.map(context => context.parentNodeId);
    }
  },
  methods: {
    changeContextTo(index) {
      if(index !== this.subgroupContexts.length - 1) {
        this.$emit("changeSubgroupContextTo", index);
      }
    },
    emitSubgroupSetContext(context) {
      this.subgroupIsReady = false;

      this.$nextTick(() => {
        this.$emit('subgroupSetContext', context);
      });
    },
    closeLastContext() {
      this.subgroupIsReady = false;

      this.$nextTick(() => {
        this.$emit('closeLastContext');
      });
    },
    closeAllContexts() {
      this.subgroupIsReady = false;

      this.$nextTick(() => {
        this.$emit('closeAllContexts');
      });
    },
    passEventToParent(eventName, event) {
      if(typeof eventName !== "string") {
        console.error("Cant pass event to parent, wrong params");
        return;
      }

      this.$emit("passEvent", {event, passedEventName: eventName, subgroupContextPath: this.nodeIdPath});
    }
  },
}
</script>

<style lang="scss" scoped>
.editor-subgroup {
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  z-index: 100;
}

.editor-subgroup__top-line {
  position: absolute;
  left: 30px;
  top: 20px;
  z-index: 11;
  display: flex;
  align-items: flex-start;
}

.editor-subgroup__path {
  margin-left: 40px;
}

.editor-subgroup__path-title {
  font-size: 14px;
  font-weight: bold;
  color: #555555;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.editor-subgroup__path-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 45vw;
  margin-left: -30px;
  margin-top: -5px;
  padding-left: 0 !important;
}

.editor-subgroup__path-item {
  margin-left: 30px;
  margin-top: 5px;
  position: relative;
  font-size: 13px;
  text-transform: lowercase;
  cursor: pointer;

  &:last-child {
    font-weight: bold;
    cursor: default;
  }
  
  &:last-child::after {
    display: none;
  }

  &::after {
    content: "";
    display: block;
    width: 5px;
    height: 5px;
    border-right: 2px solid #db7468;
    border-top: 2px solid #db7468;
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translate(0, -50%) rotate(45deg);
  }
}
</style>