<template>
  <svg id="icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2v1H5V2H2v3h1v7H2v3h3v-1h4v-1H5v-1H4V5h1V4h7v1h1v4h1V5h1V2zM4 14H3v-1h1zM4 4H3V3h1zm10 0h-1V3h1zm8 8V9h-3v1h-7V9H9v3h1v7H9v3h3v-1h7v1h3v-3h-1v-7zm-12-2h1v1h-1zm1 11h-1v-1h1zm8-1h-7v-1h-1v-7h1v-1h7v1h1v7h-1zm2 1h-1v-1h1zm-1-10v-1h1v1z" />
    <path fill="none" d="M0 0h24v24H0z" />
  </svg>
</template>

<script>
  export default {
    name: "iconUngroup"
  }
</script>

<style>

</style>