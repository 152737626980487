/**
 * Создаёт шейдер и возвращает его.
 *
 * @param {WebGLRenderingContext} gl
 * @param type
 * @param {string} source
 *
 * @return {WebGLShader}
 */
export const createShader = (gl, type, source) => {
    let message = '';
    const shader = gl.createShader(type);

    gl.shaderSource(shader, source);
    gl.compileShader(shader);

    message = gl.getShaderInfoLog(shader);

    if(message.length) {
        throw new Error(`Cant compile shader ${type}: ${message}`);
    }

    return shader;
};

/**
 *
 * Создаёт шейдерную программу.
 *
 * @param {WebGLRenderingContext} gl
 * @param {string} vertexShader
 * @param {string} fragmentShader
 *
 * @return {WebGLProgram}
 */
export default (gl, vertexShader, fragmentShader) => {
    if(typeof vertexShader !== 'string' || typeof fragmentShader !== 'string') {
        throw new Error('Wrong program parameters');
    }

    const vs = createShader(gl, gl.VERTEX_SHADER, vertexShader);
    const fs = createShader(gl, gl.FRAGMENT_SHADER, fragmentShader);

    const program = gl.createProgram();

    gl.attachShader(program, vs);
    gl.attachShader(program, fs);

    gl.linkProgram(program);

    const message = gl.getProgramInfoLog(program);

    if(message || message.length) {
        throw new Error(`Cant link program: ${message}`)
    }

    gl.deleteShader(vs);
    gl.deleteShader(fs);

    return program;
};
