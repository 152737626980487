<template>

  <ul class="ui-guide-hotkeys">
    <li
        class="ui-guide-hotkeys__item"
        v-for="(hotkey, index) in hotkeys"
        :key="index"
    >
      <span class="ui-guide-hotkeys__key">{{hotkey.key}}</span>
      <span class="ui-guide-hotkeys__description">{{hotkey.description}}</span>
    </li>
  </ul>

</template>

<script>
export default {
  data() {
    return {
      hotkeys: [
        {
          key: 'B',
          description: 'Enables/Disables grid snaps'
        },
        {
          key: 'I',
          description: 'Opens/Closes help information'
        },
        {
          key: 'F',
          description: 'Shifts the canvas position to the nodes'
        },
        {
          key: 'M',
          description: 'Enables/Disables editor mode'
        },
        {
          key: 'Delete',
          description: 'Removes selected nodes'
        },
        {
          key: '+/-',
          description: 'Zooms in/out'
        },
        {
          key: 'Ctrl + C',
          description: 'Copies selected nodes'
        },
        {
          key: 'Ctrl + X',
          description: 'Cuts selected nodes'
        },
        {
          key: 'Ctrl + V',
          description: 'Inserts selected nodes'
        },
        {
          key: 'Ctrl + Z',
          description: 'Undoes previous action'
        },
        {
          key: 'Ctrl + F',
          description: 'Search node'
        },
      ]
    };
  },
}
</script>

<style scoped lang="scss">

.ui-guide-hotkeys {}

.ui-guide-hotkeys__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EDEAE5;
  font-size: 16px;
  line-height: 1.4;
  padding: 10px 0;


  &:last-child {
    border-bottom: none;
  }

  @media screen and (max-width: 950px){
    flex-direction: column;
  }
}

.ui-guide-hotkeys__key {
  flex-basis: 33.333%;

  margin-right: 10px;
  font-weight: bold;
  text-transform: uppercase;
}

.ui-guide-hotkeys__description {
  flex-grow: 1;
}

</style>