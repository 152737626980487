import {GROUP_TYPE} from "../../../../nodes/group/index.js";
import {GROUP_OUTPUT_TYPE} from "../../../../nodes/group-output/index.js";

import compareNodePositions from "../compareNodePositions.js";

export default (node) => {
    if(typeof node !== "object" || node.type !== GROUP_TYPE || typeof node.grouped !== "object") {
        console.error("Cant get group node outputs");
        return;
    }

    const outputs = Object.values(node.grouped.nodes).filter(node => node.type === GROUP_OUTPUT_TYPE);

    const connectedInnerOutputs = outputs.sort(compareNodePositions);

    return connectedInnerOutputs.reduce((acc, node) => {
        acc.outputs.push(node.id);
        acc.outputNames.push(node.displayName ?? "");
        return acc;
    }, {outputs: [], outputNames: []});
}