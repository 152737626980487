/**
 * Удаляет ноду из списка нод
 * @param nodeId
 * @param nodeList
 */
export default (nodeId, nodeList) => {
    if(typeof nodeId !== "string" || typeof nodeList !== "object" || !(nodeId in nodeList)) {
        console.error('Cant delete node, wrong params');
        return;
    }

    delete nodeList[nodeId];

    return true;
}