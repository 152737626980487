<template>
  <div class="nodes"
       :style="{'transform': `translate3d(${-position[0]}px, ${position[1]}px, 0) scale(${scale})`}"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    position: {
      type: Array,
      required: true,
    },
    scale: {
      type: Number,
      required: true,
    },
    nodes: {
      type: Array,
      required: false,
    },
    links: {
      type: Array,
      required: false,
    },
  },
}
</script>

<style lang="scss" scoped>

.nodes {
  position: absolute;
  z-index: 10;
  will-change: transform;
}

</style>