export default {
    methods: {
        /**
         * Устанавливает размеры и viewport канваса под его родителя
         * @param {WebGLRenderingContext} gl
         */
        setCanvasSizesByParent(gl) {
            if(!gl.canvas.parentElement) return;

            const   width = gl.canvas.parentElement.clientWidth,
                    height = gl.canvas.parentElement.clientHeight;

            gl.canvas.width = width;
            gl.canvas.height = height;

            gl.viewport(0,0, width, height);
        }
    }
}