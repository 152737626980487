<template>
  <div
      class="search"
      @keydown.stop
      @keyup.stop
      @keypress.stop
      :class="{'search-not-border-top': notBorderTop}"
  >
    <component :is="icons['iconSearch']" 
        class="search__icon" 
        :class="{active: innerValue.length}" />
    <input
        v-if="searchByEnter"
        class="search__input"
        type="text"
        :placeholder="searchPlaceholder"
        v-model.trim="innerValue"
        @input="$emit('clearSearch')"
        @keyup.enter="$emit('input', innerValue)"
    >
    <input
        v-else
        class="search__input"
        type="text"
        :placeholder="searchPlaceholder"
        v-model.trim="innerValue"
        @input="$emit('input', innerValue)"
    >
    <button
        class="search__delete"
        v-if="innerValue.length"
        @click="resetValue"
    >
      <component :is="icons['iconCross']" 
          class="search__delete-icon" />
    </button>
  </div>
</template>

<script>
import iconsVue from "../../mixins/iconsVue.js";

export default {
  mixins: [iconsVue],
  props: {
    searchPlaceholder: {
      type: String,
      default: 'Search nodes...'
    },
    value: {
      type: String
    },
    notBorderTop: {
      type: Boolean,
      default: false
    },
    searchByEnter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  methods: {
    resetValue() {
      this.innerValue = '';
      this.$emit('input', this.innerValue);
    }
  }
}
</script>

<style scoped lang="scss">

.search {
  display: flex;
  align-items: center;
  position: relative;
  border-top: 1px solid #dbdfe7;
  border-bottom: 1px solid #dbdfe7;

  &-not-border-top{
    border-top:0;
  }
}

.search__input {
  width: 100%;
  padding: 15px 0 15px 50px;
  font-size: 16px;
  border: none;
  outline: none;

  &::placeholder {
    color: #909399;
  }
}

.search__icon {
  width: 19px;
  height: 19px;
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 50%;
  transform: translate(-50%, -50%);
  fill: #909399;

  &.active {
    fill: #ff6d5a;
  }
}

.search__delete {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-color: #ff6d5a;
  border-radius: 50%;
  border: none;
  outline: none;
}

.search__delete-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  fill: #fff;
}

</style>