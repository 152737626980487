import generateNodeId from "./generateNodeId.js";
import getNodeType from "./getNodeType.js";

export default (nodeData, availableNodes) => {
    if(typeof nodeData !== "object" && typeof availableNodes !== "object") {
        console.error('Cant create node, wrong params');
        return;
    }

    if(!(nodeData.type in availableNodes)) {
        console.error('Cant add node, available node type doesnt exists');
        console.error(nodeData.type, Object.keys(availableNodes));
        return;
    }

    let node = {
        id: generateNodeId(),
        ...nodeData,
    };

    const type = getNodeType(node, availableNodes);

    node.displayName = nodeData.displayName ?? type.name;
    node.position = nodeData.position ?? [0,0];
    node.selected = nodeData.selected ?? false;

    return node;
}