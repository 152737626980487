import deepCopy from "../../deepCopy.js";
import getIngoingConnections from "./getIngoingConnections.js";
import optimizeEmptyConnectionsStruct from "../optimizeEmptyConnectionsStruct.js";

export default (nodeId, connections) => {
    let updatedConnections = deepCopy(connections);

    if(Object.hasOwnProperty.call(updatedConnections, nodeId)) {
        delete updatedConnections[nodeId];
    }

    const outgoingConnections = getIngoingConnections(nodeId, updatedConnections);

    for(const startNodeId in outgoingConnections) {
        const nodeStartInfo = outgoingConnections[startNodeId];

        for(const outputName of nodeStartInfo) {
            updatedConnections[startNodeId][outputName] = updatedConnections[startNodeId][outputName].filter(endNodeObject => endNodeObject['endNodeId'] !== nodeId);
        }
    }

    return optimizeEmptyConnectionsStruct(updatedConnections);
}