<template>
  <graph-editor-ui-guide-col-wrapper>
    <graph-editor-ui-guide-col
        v-for="(col, index) in cols"
        :key="index"
        :icon="col.icon"
        :title="col.title"
        :description="col.description"
    />
  </graph-editor-ui-guide-col-wrapper>
</template>

<script>
import GraphEditorUiGuideColWrapper from "../GraphEditorUiGuideColWrapper.vue";
import GraphEditorUiGuideCol from "../GraphEditorUiGuideCol.vue";

export default {
  components: {
    GraphEditorUiGuideCol,
    GraphEditorUiGuideColWrapper
  },
  data() {
    return {
      cols: [
        {
          icon: 'iconKeys',
          title: 'Using the keyboard',
          description: 'In order to move using the keyboard, you need to press the arrows, after which the canvas will move in the direction of the arrow'
        },
        {
          icon: 'iconMouse',
          title: 'Using the mouse wheel',
          description: 'In order to move using the mouse wheel, you need to turn the mouse wheel in any direction. In order for movement to occur along a different axis, you need to do this while holding down the Shift key.'
        },
        {
          icon: 'iconMouse',
          title: 'By holding down the mouse wheel',
          description: 'In order to move using a clamped wheel, you need to hold the wheel on the mouse and move the mouse in any direction'
        },
      ],
    };
  },
}
</script>

<styles></styles>