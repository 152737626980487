<template>
  <svg viewBox="12 10  24 28" fill="none"  version="1.1" id="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px">
    <path d="M12.5488 21.0221C12.5488 21.4015 12.8564 21.7091 13.2359 21.7091C13.6153 21.7091 13.9229 21.4015 13.9229 21.0221V13.9229H21.0221C21.4015 13.9229 21.7091 13.6153 21.7091 13.2359C21.7091 12.8564 21.4016 12.5488 21.0221 12.5488H13.2389L13.2359 12.5488C12.8564 12.5488 12.5488 12.8564 12.5488 13.2358V21.0221Z" fill="black"/>
    <path d="M26.9763 12.5488C26.5969 12.5488 26.2893 12.8564 26.2893 13.2359C26.2893 13.6153 26.5969 13.9229 26.9763 13.9229H34.0755V21.0221C34.0755 21.4015 34.3831 21.7091 34.7626 21.7091C35.142 21.7091 35.4496 21.4015 35.4496 21.0221V13.2375L35.4496 13.2359C35.4496 13.2121 35.4484 13.1887 35.446 13.1656C35.4134 12.8437 35.1585 12.5875 34.8372 12.5528C34.8127 12.5502 34.7878 12.5488 34.7626 12.5488L26.9763 12.5488Z" fill="black"/>
    <path d="M12.5488 26.9763V34.7626C12.5488 34.8219 12.5563 34.8794 12.5705 34.9343C12.6467 35.2306 12.9157 35.4496 13.2358 35.4496H21.0221C21.4015 35.4496 21.7091 35.142 21.7091 34.7626C21.7091 34.3831 21.4015 34.0755 21.0221 34.0755H13.9229V26.9763C13.9229 26.5969 13.6153 26.2893 13.2359 26.2893C12.8564 26.2893 12.5488 26.5969 12.5488 26.9763Z" fill="black"/>
    <path d="M26.2893 34.7626C26.2893 34.3831 26.5969 34.0755 26.9763 34.0755H34.0297V26.9763C34.0297 26.5969 34.3373 26.2893 34.7168 26.2893C35.0962 26.2893 35.4038 26.5969 35.4038 26.9763V34.5154C35.4334 34.5921 35.4496 34.6754 35.4496 34.7626C35.4496 35.142 35.142 35.4496 34.7626 35.4496H26.9763C26.5969 35.4496 26.2893 35.142 26.2893 34.7626Z" fill="black"/>
    <path d="M30.9839 25.3046C30.9839 24.6653 30.7839 24.1463 30.4538 23.748C30.1334 23.3614 29.7236 23.1274 29.3554 22.984C28.9861 22.8402 28.627 22.7751 28.3672 22.7447C28.2355 22.7293 28.1246 22.7223 28.0444 22.7191C28.0043 22.7176 27.9716 22.7169 27.9475 22.7168C27.9354 22.7167 27.9255 22.7167 27.9179 22.7167L27.9108 22.7168H20.307L21.4145 21.6093C21.6828 21.341 21.6828 20.906 21.4145 20.6377C21.1462 20.3694 20.7112 20.3694 20.4429 20.6377L18.1759 22.9047C18.0387 23.0418 17.9717 23.2225 17.9747 23.4022C17.9718 23.5818 18.0388 23.7623 18.1759 23.8994L20.4429 26.1664C20.7112 26.4347 21.1462 26.4347 21.4145 26.1664C21.6828 25.8981 21.6828 25.4631 21.4145 25.1948L20.3105 24.0908H27.9152C27.9188 24.0908 27.9223 24.0908 27.9259 24.0907L27.9262 24.0907C27.9276 24.0907 27.9311 24.0907 27.9365 24.0908C27.9473 24.0908 27.9657 24.0911 27.9907 24.0921C28.0408 24.0941 28.116 24.0987 28.2075 24.1094C28.3943 24.1313 28.6306 24.1763 28.8568 24.2644C29.084 24.3529 29.2696 24.4724 29.3958 24.6247C29.5123 24.7653 29.6099 24.9705 29.6099 25.3046C29.6099 25.623 29.5148 25.8274 29.3941 25.9743C29.2636 26.133 29.0723 26.26 28.841 26.3568C28.6111 26.4529 28.372 26.5064 28.184 26.5351C28.0917 26.5491 28.0159 26.5565 27.9653 26.5604C27.9402 26.5623 27.9215 26.5633 27.9105 26.5638C27.9076 26.564 27.9052 26.5641 27.9034 26.5641L27.9 26.5643L27.8996 26.5643C27.5214 26.5729 27.2211 26.8858 27.2283 27.2643C27.2356 27.6437 27.5491 27.9453 27.9284 27.938L27.9152 27.2511C27.9284 27.938 27.9287 27.938 27.929 27.938L27.9296 27.938L27.931 27.938L27.9345 27.9379C27.9371 27.9378 27.9403 27.9377 27.9441 27.9376C27.9517 27.9374 27.9615 27.937 27.9734 27.9364C27.9973 27.9353 28.0299 27.9335 28.0698 27.9305C28.1495 27.9244 28.2598 27.9134 28.3908 27.8935C28.6493 27.8541 29.0056 27.7773 29.3712 27.6244C29.7353 27.4722 30.1394 27.2314 30.4555 26.8469C30.7813 26.4505 30.9839 25.9365 30.9839 25.3046Z" fill="black"/>
  </svg>

</template>

<script>
  export default {
    name: "iconBack"
  }
</script>

<style>

</style>