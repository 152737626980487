<template>
  <div
      class="select"
      :style="{
        left: `${this.min[0]}px`,
        top: `${this.min[1]}px`,
        width: `${this.width}px`,
        height: `${this.height}px`,
      }"
  ></div>
</template>

<script>

export default {
  props: {
    selectStart: Array,
    selectEnd: Array,
  },
  computed: {
    min() {
      return [
        Math.min(this.selectStart[0], this.selectEnd[0]),
        Math.min(this.selectStart[1], this.selectEnd[1]),
      ];
    },
    max() {
      return [
        Math.max(this.selectStart[0], this.selectEnd[0]),
        Math.max(this.selectStart[1], this.selectEnd[1]),
      ];
    },
    width() {
      return this.max[0] - this.min[0];
    },
    height() {
      return this.max[1] - this.min[1];
    }
  }
}
</script>

<style lang="scss" scoped>

.select {
  border: 2px dashed #f9975c;
  position: absolute;
  z-index: 9;
  user-select: none;
  pointer-events: none;
  will-change: display, left, top, right, bottom;
}

</style>