import ic from "./icon.svg";

import {SYSTEM_CATEGORY} from "../categories.js";
export const GROUP_OUTPUT_TYPE = "groupOutput";

export default {
    name: 'Output',
    description: 'Output node',
    icon: ic + "#icon",
    input: true,
    outputs: [],
    category: SYSTEM_CATEGORY,
    canBeDeleted: false,
    canBeCopied: false,
}