<template>
  <div>
    <graph-editor-arrow
        class="temp-connection"
        v-if="tempConnection"
        :from="tempConnection.startPosition"
        :to="tempConnection.endPosition"
    />
    <slot></slot>
  </div>
</template>

<script>
import GraphEditorArrow from "../components/GraphEditorArrow.vue";

export default {
  components: {
    GraphEditorArrow,
  },
  props: {
    tempConnection: {
      type: Object,
      required: false,
    },
  },
}

</script>

<style lang="scss">
.temp-connection {
  pointer-events: none !important;

  & path, & svg {
    pointer-events: none !important;
  }
}
</style>